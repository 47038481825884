import React, { useContext, useEffect, useState } from "react";
import "./SIWatchList.css";
import MenuIcon from '../../assets/images/icons/MenuIcon.png';
import SearchIconColor from '../../assets/images/icons/SearchIconColor.png';
import combinationIcon from '../../assets/images/icons/combinationIcon.png';
import binIcon from '../../assets/images/icons/binIcon.png';
import { AppContext } from "../../context/Context";
import { useLoading } from "../../context/LoadingContext/LoadingContext";
import { deleteWatchListForCompanyByUserId, getTrackingWatchListByUserId } from "../../api/watchList/watchList";

const SIWatchList = () => {
    const [trackingWatchList, setTrackingWatchList] = useState([]);
    const { user } = useContext(AppContext);
    const { setIsLoading } = useLoading();

    useEffect(() => {
        const fetchTrackingWatchList = async () => {
            if (user?.uid) {
                try {
                    setIsLoading(true);
                    const result = await getTrackingWatchListByUserId(user.uid);
                    setTrackingWatchList(result);
                    setIsLoading(false);
                } catch (error) {
                    setIsLoading(false);

                    console.error('Failed to fetch tracking watchlist:', error);
                }
            }
        };

        fetchTrackingWatchList();
    }, [user]);

    const trackingWatchListOnClick = async tracking => {
        try {
            if (tracking) {
                setIsLoading(true);
                const deleteObj = await deleteWatchListForCompanyByUserId(
                    tracking?.user_id,
                    tracking?.company_number,
                    tracking?.relationship
                );
                if (deleteObj?.success) {
                    const result = await getTrackingWatchListByUserId(user.uid);
                    setTrackingWatchList(result);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            }
        } catch (error) {
            setIsLoading(false);
        }
    };

    const onRowClick = (customer, index) => {
        trackingWatchListOnClick(customer);
    };

    return (
        <div className="up-record-list-container">
            {/* Table Header */}
            <div className="up-record-header">
                <span className="up-column-title">
                    <img style={{ height: '26px', width: '26px', marginRight: '5px' }} src={SearchIconColor} alt="Icon" />
                    Entity Saved</span>
                <span className="up-column-title">
                    <img style={{ height: '26px', width: '26px', marginRight: '5px' }} src={combinationIcon} alt="Icon" />
                    Latest Activity</span>
                <span className="up-column-title">
                    <img style={{ height: '26px', width: '26px', marginRight: '5px' }} src={MenuIcon} alt="Icon" />
                    Status</span>
                <span className="up-column-title"></span>
            </div>

            {/* Records */}
            {trackingWatchList.map((tracking, index) => (
                <div key={index} className="up-record-row">
                    <span className="up-record-item">{tracking?.tracking?.company_name}</span>
                    <span className="up-record-item">
                        {tracking?.tracking?.message}
                        <div style={{ display: 'flex', gap: '2px' }}>
                            <div>{tracking?.tracking?.updatedOn?.timeString}</div>
                            <div>
                                {tracking?.tracking?.updatedOn?.formattedDate}
                            </div>
                        </div>
                    </span>
                    <span className="up-record-item">
                        <span className="up-wl-status" style={{
                            backgroundColor: tracking?.tracking?.company_status === "active" ? "green" : "red",
                        }}>
                            {tracking?.tracking?.company_status}
                        </span>
                    </span>
                    <span className="up-record-item">
                        <span className="up-column-title" onClick={() => onRowClick(tracking, index)} style={{ cursor: "pointer" }}>
                            <img src={binIcon} alt="Icon" />
                        </span>
                    </span>
                </div>
            ))}
        </div>
    );
};

export default SIWatchList;
