import React, { useContext, useEffect, useState } from "react";
import "./SavedItemsList.css";
import {
    deleteNetworkGraphByUserIdAndDocumentId,
    getNetworkGraphByUserId,
} from '../../api/corporateExplorer/corporateExplorer';
import { AppContext } from '../../context/Context';
import Pagination from '../../components/Pagination/Pagination';
import { convertTimestampToDateTime, getEntityNamesFromObjectGraph } from "../../utils/helper";
import { useNavigate } from 'react-router-dom';
import MenuIcon from '../../assets/images/icons/MenuIcon.png';
import SearchIconColor from '../../assets/images/icons/SearchIconColor.png';
import combinationIcon from '../../assets/images/icons/combinationIcon.png';
import BinaryTreeIcon from '../../assets/images/icons/BinaryTreeIcon.png';
import CloudIcon from '../../assets/images/icons/CloudIcon.png';
import binIcon from '../../assets/images/icons/binIcon.png';
import { useLoading } from "../../context/LoadingContext/LoadingContext";

const SavedItemsList = () => {
    const { user, setClGraph } = useContext(AppContext);
    const { setIsLoading } = useLoading();
    const [currentPage, setCurrentPage] = useState(0);
    const [history, setHistory] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const navigate = useNavigate();

    const getGraphHistory = async () => {
        setIsLoading(true);
        const graphHistoryResponse = await getNetworkGraphByUserId(
            user?.FirebaseUser?.user_id,
            currentPage + 1,
            5
        );
        setHistory(graphHistoryResponse?.data);
        setTotalPages(graphHistoryResponse?.totalPages);
        setIsLoading(false);
    };

    const deleteGraphFromHistory = async graphs => {
        try {
            setIsLoading(true);
            let deleted = await deleteNetworkGraphByUserIdAndDocumentId(
                user.uid,
                graphs.id
            );
            if (deleted.success) {
                await getGraphHistory();
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    };

    const handlePageChange = newPage => {
        setCurrentPage(newPage);
    };

    useEffect(() => {
        if (user) {
            getGraphHistory();
        }
    }, [user, currentPage]);

    const viewOnCorporateLinksGraph = graphs => {
        setClGraph(previousState => ({
            ...previousState,
            graph: graphs?.graphs?.result?.graphs,
            combination: graphs?.combination,
        }));
        navigate('/cl-dashboard/cl-graph');
    };

    return (
        <div className="up-saved-list-container">
            {/* Table Header */}
            <div className="up-saved-items-header">
                <span className="up-saved-column-title">
                    <img style={{ height: '26px', width: '26px', marginRight: '5px' }} src={MenuIcon} alt="Icon" />
                    Generated On</span>
                <span className="up-saved-column-title">
                    <img style={{ height: '26px', width: '26px', marginRight: '5px' }} src={SearchIconColor} alt="Icon" />
                    Entity Searched</span>
                <span className="up-saved-column-title">
                    <img style={{ height: '26px', width: '26px', marginRight: '5px' }} src={combinationIcon} alt="Icon" />
                    Combination Type</span>
                <span className="up-saved-column-title">
                    <img style={{ height: '26px', width: '26px', marginRight: '5px' }} src={BinaryTreeIcon} alt="Icon" />
                    View Graph</span>
                <span className="up-saved-column-title"></span>
            </div>

            {history.map((item, index) => (
                <div key={index} className="up-saved-row">
                    <span className="up-saved-item">{convertTimestampToDateTime(item?.timestamp)}</span>
                    <span className="up-saved-item">{getEntityNamesFromObjectGraph(item)}</span>
                    <span className="up-saved-item">{item?.combination?.combinationType}</span>
                    <span className="up-saved-item">
                        <a href="#" className="up-saved-link" onClick={() => viewOnCorporateLinksGraph(item)}>View Graph</a>
                    </span>
                    <span className="up-saved-item">
                        <img style={{ cursor: 'pointer', height: '22px', width: '22px', marginRight: '5px' }} src={CloudIcon} alt="Icon" />
                        <img style={{ cursor: 'pointer', height: '22px', width: '22px' }} src={binIcon} alt="Icon" onClick={() => deleteGraphFromHistory(item)} />
                    </span>
                </div>
            ))}
            <div style={{ marginTop: '20px' }}></div>
            <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
            />
        </div>
    );
};

export default SavedItemsList;
