import React, { useContext, useEffect, useState } from 'react';
import Login from './Login'; // Import the Login component
import Signup from './Signup'; // Import the Signup component
import './UserAuthHandler.css'; // Import the modal styling
import { AppContext } from '../../context/Context';
import SubscriptionPlans from './SubscriptionPlans';
import { useLocation, useNavigate } from 'react-router';
import URLService from '../../utils/URLService';
import loginScreenPicture from '../../assets/images/icons/LoginScreenPicture.png';
import ForgetScreen from './ForgetScreen';
import GetCodeScreen from './GetCodeScreen';
import ResetPasswordScreen from './ResetPasswordScreen';

const UserAuthHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useContext(AppContext);
  const [step, setStep] = useState('login'); // 'login', 'plans', or 'signup'
  const [selectedPlan, setSelectedPlan] = useState('Freemium'); // State to store the selected plan

  const toggleSignup = () => {
    if (step === 'login') {
      setStep('signup'); // Show plans when the user clicks Sign Up
    } else {
      setStep('login'); // Back to login when user clicks back
    }
  };

  const toggleForgotPassword = () => {
    setStep('forgotPassword'); // Show the Forgot Password screen
  };

  useEffect(() => {
    let authType = URLService.getParam('authType');
    let plan = URLService.getParam('plan');

    if (plan !== null && authType !== null) {
      setSelectedPlan(plan);
      setStep(authType);
    }

    if (plan === null && authType !== null) {
      setStep(authType);
    }
  }, []);

  useEffect(() => {
    if (
      user &&
      user?.hasOwnProperty('FirebaseUser') &&
      user?.FirebaseUser?.is_payment_done === true
    ) {
      navigate('/services');
    }
  }, [user]);

  return (
    <div className="user-auth-handler-parent-container">
      {/* Modal */}
      <div className="user-auth-handler-main-container">
        <div
          className="user-auth-handler-modal-content"
          onClick={e => e.stopPropagation()}
        >
          {step === 'login' && (
            <Login
              toggleForgotPassword={toggleForgotPassword}
              selectedPlan={selectedPlan}
            />
          )}
          {step === 'signup' && <Signup selectedPlan={selectedPlan} />}
          {step === 'forgotPassword' && <ForgetScreen setStep={setStep} />}
          {step === 'reset' && <ResetPasswordScreen />}
          {step === 'getCode' && <GetCodeScreen />}
          {/* Toggle Between Login and Signup */}
          <p className="user-auth-handler-toggle-text">
            {step === 'login' ? (
              <>
                Don’t have an account?{' '}
                <span
                  className="user-auth-handler-toggle-button"
                  onClick={toggleSignup}
                >
                  Sign Up
                </span>
              </>
            ) : step === 'getCode' ? (
              <>
                If you don’t receive the code,{' '}
                <span className="user-auth-handler-toggle-button">Resend</span>
              </>
            ) : (
              <>
                Already have an account?{' '}
                <span
                  className="user-auth-handler-toggle-button"
                  onClick={toggleSignup}
                >
                  Login
                </span>
              </>
            )}
          </p>
        </div>

        <div className="user-auth-photo-container">
          <div className="user-auth-photo-container-inner">
            <div className="user-auth-photo-banner"></div>
            <h1 className="user-auth-photo-heading">Corporate Profile</h1>
            <p className="user-auth-photo-text">
              Explore details on a corporate company or officer and their
              linkages
            </p>
            <div className="dots">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAuthHandler;
