import React from "react";
import "./SICorporateSearch.css";
import MenuIcon from '../../assets/images/icons/MenuIcon.png';
import SearchIconColor from '../../assets/images/icons/SearchIconColor.png';
import combinationIcon from '../../assets/images/icons/combinationIcon.png';
import overallResult from '../../assets/images/icons/overallResult.png';
import binIcon from '../../assets/images/icons/binIcon.png';

const SICorporateSearch = () => {
    const records = [
        {
            date: "24 March 2024",
            entity: "ABCDE",
            reference: "123456",
            result: "Positive",
            type: "Normal",
            link: "abcdef12345ab...",
        },
        {
            date: "24 March 2024",
            entity: "ABCDE",
            reference: "123456",
            result: "Positive",
            type: "Normal",
            link: "abcdef12345ab...",
        },
        {
            date: "24 March 2024",
            entity: "ABCDE",
            reference: "123456",
            result: "Positive",
            type: "Normal",
            link: "abcdef12345ab...",
        },
        {
            date: "24 March 2024",
            entity: "ABCDE",
            reference: "123456",
            result: "Positive",
            type: "Normal",
            link: "abcdef12345ab...",
        },
        {
            date: "24 March 2024",
            entity: "ABCDE",
            reference: "123456",
            result: "Positive",
            type: "Normal",
            link: "abcdef12345ab...",
        },
    ];

    return (
        <div className="up-record-list-container">
            {/* Table Header */}
            <div className="up-record-header">
                <span className="up-column-title">
                    <img style={{ height: '26px', width: '26px', marginRight: '5px' }} src={MenuIcon} alt="Icon" />
                    Generated On</span>
                <span className="up-column-title">
                    <img style={{ height: '26px', width: '26px', marginRight: '5px' }} src={SearchIconColor} alt="Icon" />
                    Entity Searched</span>
                <span className="up-column-title">
                    <img style={{ height: '26px', width: '26px', marginRight: '5px' }} src={combinationIcon} alt="Icon" />
                    Combination Type</span>
                <span className="up-column-title">
                    <img style={{ height: '26px', width: '26px', marginRight: '5px' }} src={overallResult} alt="Icon" />
                    View Graph</span>
                <span className="up-column-title"></span>
            </div>

            {/* Records */}
            {records.map((record, index) => (
                <div key={index} className="up-record-row">
                    <span className="up-record-item">{record.date}</span>
                    <span className="up-record-item">{record.entity}</span>
                    <span className="up-record-item">{record.reference}</span>
                    <span className="up-record-item">
                        <a href="#" className="up-record-link">{record.link}</a>
                    </span>
                    <span className="up-record-item">
                        <img style={{ cursor: 'pointer', height: '22px', width: '22px' }} src={binIcon} alt="Icon"/>
                    </span>
                </div>
            ))}
        </div>
    );
};

export default SICorporateSearch;
