import React, { useContext, useEffect, useState } from 'react';
import Shareholders from '../../CorporateExplorerFilter/CompanyInfoModal/Shareholders/Shareholders';
import { debounce } from '../../../utils/helper';
import { corporateExplorerShareholders } from '../../../api/corporateExplorer/corporateExplorer';

function CEIShareholder({ selectedCompany, forceMobileView }) {
  const [isLoading, setIsLoading] = useState(false);
  const [shareholders, setShareholders] = useState([]);
  const [
    shareholdersBackeupForSearchFilter,
    setShareholdersBackeupForSearchFilter,
  ] = useState([]);

  const debouncedSearch = debounce(event => {
    if (event === '') {
      setShareholders(shareholdersBackeupForSearchFilter);
      return;
    }

    let filteredShareHolders = filterPeopleByQuery(
      event,
      shareholdersBackeupForSearchFilter
    );
    setShareholders(filteredShareHolders);
  }, 500);

  const filterPeopleByQuery = (queryString, dataList) => {
    // Convert the query string to lower case for case-insensitive matching
    const lowerCaseQuery = queryString.toLowerCase();

    // Function to filter people in a given list
    const filterPeople = peopleList => {
      return peopleList.filter(person =>
        person.name.toLowerCase().includes(lowerCaseQuery)
      );
    };

    // Create a new filtered list for both Ordinary and Preferred types
    const filteredDataList = dataList.map(data => {
      return {
        ...data,
        people: filterPeople(data.people),
      };
    });

    return filteredDataList;
  };

  useEffect(() => {
    setIsLoading(true);
    const getShareholders = async () => {
      let companyNumber = selectedCompany?.company_number;
      try {
        if (companyNumber && shareholders.length < 1) {
          const shareholdersRes =
            await corporateExplorerShareholders(companyNumber);
          // Handle the shareholders data here
          setShareholders(shareholdersRes);
          setShareholdersBackeupForSearchFilter(shareholdersRes);
          setIsLoading(false);
        } else {
          setShareholders(shareholders);
          setShareholdersBackeupForSearchFilter(shareholders);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    };
    getShareholders();
  }, []);

  return (
    <Shareholders
      debouncedSearch={debouncedSearch}
      isLoading={isLoading}
      shareholders={shareholders}
      smallIcons={true}
      selectedCompany={selectedCompany}
      forceMobileView={forceMobileView}
    />
  );
}

export default CEIShareholder;
