import React, { useContext, useEffect, useState } from 'react';
import HeaderDropdown from '../../../components/HeaderDropdownNative';
import './CoporateLinksGraph.css';
import CorporateLinksGraphRightSidebar from '../../../components/CorporateLinks/CorporateLinksGraphRightSidebar/CorporateLinksGraphRightSidebar';
import CLGraph from './../../../components/CorporateLinks/CLGraph/CLGraph';
import { AppContext } from '../../../context/Context';
import CorporateLinksGraphLeftSidebar from './../../../components/CorporateLinks/CorporateLinksGraphLeftSidebar/CorporateLinksGraphSidebar';

import {
  getCompanyInfoByCompanyNumber,
  getOfficerInfoByCompanyNumber,
  getPersonInsights,
  getRelationShips,
  getShareholdersOfCompaniesInNetworkGraph,
} from '../../../api/corporateExplorer/corporateExplorer';
import CONSTANTS from '../../../utils/constants';
import { useAuth } from '../../../context/AuthProvider';
import LocalStorageService from '../../../utils/LocalStorageServices';
import { isArray } from 'lodash';
import { addUniqueNode, getNodeNameById } from '../../../utils/helper';

const CorporateLinksGraph = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [rightSidebarOpen, setRightSidebarOpen] = useState(false);
  const { clGraph, setClGraph, user, trackShareholder, setTrackShareholder } =
    useContext(AppContext);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedOfficer, setSelectedOfficer] = useState(null);
  const [accountInfo, setAccountsInfo] = useState([]);
  const [graphFromState, setGraphFromState] = useState(null);
  const [localTrackingShareholder, setLocalTrackingShareholder] = useState([]); // for on off shareholder for left side buttons
  const [isInfoLoading, setIsInfoLoading] = useState(false);
  const [isLoadingShareholders, setIsLoadingShareholders] = useState(false);

  const { logout, openLogin, openProfile } = useAuth();
  const [leftSideButtonType, setLeftSideButtonType] = useState(null);
  const [selectedGraph, setSelectedGraph] = useState(null);
  const [selectedEntityType, setSelectedEntityType] = useState(null);
  const [searchedEntitiesInGraph, setSearchedEntitiesInGraph] = useState([]);

  const resetState = () => {
    setSelectedCompany(null);
    setSelectedOfficer(null);
    setRightSidebarOpen(false);
    setAccountsInfo(null);
  };

  const toggleRightSidebar = () => {
    setRightSidebarOpen(!rightSidebarOpen);
    if (rightSidebarOpen === true) {
      setClGraph(previousState => ({
        ...previousState,
        isOpenRightSidebar: false,
      }));
    }
  };

  const trackingShareholderAction = trackShareholder => {
    const newGraph = structuredClone(clGraph?.selectedGraph);
    let edgeId = newGraph?.edges[newGraph?.edges.length - 1]?.edgeId;

    let name = getNodeNameById(
      newGraph?.nodes,
      newGraph?.meta?.selectedEntity?.ids
    )?.replaceAll(' ', '_');
    trackShareholder[name]?.map(shareholder => {
      shareholder?.shareholders?.map(item => {
        item?.people?.map(person => {
          const nodeId =
            `${person?.name}_${person?.type}_${person?.share_type}_${person?.number_of_shares}`
              ?.toLowerCase()
              ?.replaceAll(' ', '_');

          addUniqueNode(newGraph, nodeId, person);

          newGraph?.edges?.push({
            edgeId: edgeId++,
            source: nodeId,
            target: shareholder?.companyNumber,
            probability: '1',
            edgeInfo: '',
          });
        });
      });
    });

    setSelectedGraph(newGraph);
  };

  useEffect(() => {
    resetState();
    if (clGraph?.isOpenRightSidebar) {
      const getCompanyInfo = async () => {
        setRightSidebarOpen(clGraph?.isOpenRightSidebar);

        if (!clGraph?.clickedNode) return;

        try {
          if (clGraph?.clickedNode?.entityType === 'company') {
            setIsInfoLoading(true);

            const data = await getCompanyInfoByCompanyNumber(
              clGraph?.clickedNode?.id
            );
            setIsInfoLoading(false);
            setSelectedCompany(data?.companyInfo);
            setAccountsInfo(data?.accountInfo);
          }

          if (clGraph?.clickedNode?.entityType === 'person') {
            setIsInfoLoading(true);

            let preparePayLoad = {
              combinationType: 'officer',
              ids: [
                {
                  type: 'officer',
                  id: clGraph?.clickedNode?.id,
                },
              ],
            };
            preparePayLoad['graphs'] = [clGraph?.selectedGraph];

            const data = await getPersonInsights(preparePayLoad);
            setIsInfoLoading(false);
            setSelectedOfficer(data);
          }
        } catch (error) {
          setIsInfoLoading(false);
        }
      };

      getCompanyInfo();
    }
  }, [clGraph]);

  useEffect(() => {
    if (leftSideButtonType === 'All Shareholders') {
      trackingShareholderAction(trackShareholder);
    }

    if (leftSideButtonType === 'Officers & Companies') {
      setSelectedEntityType(null);
    }

    if (leftSideButtonType === 'Only Companies') {
      setSelectedEntityType('company');
    }

    if (leftSideButtonType === 'Only Officers') {
      setSelectedEntityType('person');
    }

    if (leftSideButtonType === 'default') {
      trackingShareholderAction([]);
    }
  }, [leftSideButtonType, trackShareholder]);

  useEffect(() => {
    if (clGraph?.selectedGraph?.meta?.selectedEntity?.ids) {
      setSearchedEntitiesInGraph(
        clGraph?.selectedGraph?.meta?.selectedEntity?.ids
      );
    }
  }, [selectedGraph]);

  useEffect(() => {
    let graph = clGraph?.graph;
    setGraphFromState(graph);
    setTrackShareholder([]);
    resetState();
  }, []);

  return (
    <div>
      <div className="dashboard-section">
        <div className="main-div">
          <div className="corporate-links-graph-main-content">
            <div>
              <CLGraph
                graph={graphFromState}
                setLeftSideButtonType={setLeftSideButtonType}
                leftSideButtonType={leftSideButtonType}
                setSelectedGraph={setSelectedGraph}
                selectedGraph={selectedGraph}
                isLoadingShareholders={isLoadingShareholders}
                setSelectedEntityType={setSelectedEntityType}
                selectedEntityType={selectedEntityType}
                rightSidebarOpen={rightSidebarOpen}
                searchedEntitiesInGraph={searchedEntitiesInGraph}
              />
            </div>

            {/* Right sidebar */}
            <div
              className={`corporate-links-graph-right-sidebar ${rightSidebarOpen ? 'open' : ''}`}
            >
              <CorporateLinksGraphRightSidebar
                rightSidebarOpen={rightSidebarOpen}
                toggleRightSidebar={toggleRightSidebar}
                selectedCompany={selectedCompany}
                accountInfo={accountInfo}
                selectedOfficer={selectedOfficer}
                isInfoLoading={isInfoLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorporateLinksGraph;
