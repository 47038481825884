import {
  GoogleAuthProvider,
  signInWithPopup,
  setPersistence,
  browserLocalPersistence,
} from 'firebase/auth';
import { firebaseAuth } from '../../context/firebaseContext/firebase';
import { createUser, mapUserData } from '../../utils/helper';
import { getUserById } from '../outseta/outseta';

export const loginWithGoogle = async (isLogin, selectedPlan) => {
  const provider = new GoogleAuthProvider();

  try {
    // Set session persistence to local
    await setPersistence(firebaseAuth, browserLocalPersistence);

    // Sign in with Google
    const result = await signInWithPopup(firebaseAuth, provider);
    const user = result.user;

    // Check if the user exists in your database
    let firebaseUser = await getUserById(user.uid);

    if (firebaseUser?.status === 404 && isLogin === true) {
      console.error('User not found in the database.');
      return {
        success: false,
        error: 'User not registered in the database.',
      };
    } else {
      if (firebaseUser?.status === 404 && isLogin === false) {
        let createdUser = await createUser(
          user?.uid,
          user?.email,
          selectedPlan
        );

        // Successful signup
        return {
          success: true,
          user: mapUserData(user),
          firebaseUser: createdUser,
        };
      }
    }

    // Successful login
    return {
      success: true,
      user: mapUserData(user),
      firebaseUser: firebaseUser?.data,
    };
  } catch (error) {
    let errorMessage = 'An error occurred during Google login';
    switch (error.code) {
      case 'auth/cancelled-popup-request':
        errorMessage = 'The popup request was canceled.';
        break;
      case 'auth/popup-closed-by-user':
        errorMessage = 'The popup was closed before completing the sign-in.';
        break;
      case 'auth/network-request-failed':
        errorMessage =
          'Network error. Please check your connection and try again.';
        break;
      case 'auth/account-exists-with-different-credential':
        errorMessage =
          'An account already exists with the same email but different credentials.';
        break;
      case 'auth/operation-not-allowed':
        errorMessage = 'Google sign-in is not enabled in Firebase settings.';
        break;
      default:
        errorMessage = error.message;
    }

    console.error('Google login error:', error);
    return {
      success: false,
      error: errorMessage,
      code: error.code,
    };
  }
};
