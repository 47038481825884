import React, { useState, useEffect, useContext } from 'react';
import '../JudgementChecker/CheckReviewPerson/SearchOnPerson/SearchOnPerson.css';
import WatchlistTable from '../../components/Watchlist/WatchlistTable';
import { AppContext } from '../../context/Context';
import {
  deleteWatchListForCompanyByUserId,
  getCompaniesAndOfficerHistoryForWatchlist,
  getTrackingWatchListByUserId,
} from '../../api/watchList/watchList';
import { useLoading } from '../../context/LoadingContext/LoadingContext';

function WatchlistPage() {
  const { user } = useContext(AppContext);
  const { setIsLoading } = useLoading();
  const [trackingWatchList, setTrackingWatchList] = useState([]);
  const [trackingWatchListClick, setTrackingWatchListClick] = useState([]);

  const trackingWatchListOnClick = async tracking => {
    try {
      if (tracking) {
        setIsLoading(true);
        const deleteObj = await deleteWatchListForCompanyByUserId(
          tracking?.user_id,
          tracking?.company_number,
          tracking?.relationship
        );
        if (deleteObj?.success) {
          const result = await getTrackingWatchListByUserId(user.uid);
          setTrackingWatchList(result);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchTrackingWatchList = async () => {
      if (user?.uid) {
        try {
          setIsLoading(true);
          const result = await getTrackingWatchListByUserId(user.uid);
          setTrackingWatchList(result);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);

          console.error('Failed to fetch tracking watchlist:', error);
        }
      }
    };

    fetchTrackingWatchList();
  }, [user]);

  return (
    <>
      <div className="dashboard-section">
        <div className="main-div">
          <div className="dashboard-content-div">
            <WatchlistTable
              trackingWatchList={trackingWatchList}
              trackingWatchListOnClick={trackingWatchListOnClick}
              debouncedSearch={null}
              isSearchInProgress={false}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default WatchlistPage;
