import React, { useContext } from 'react';
import { AppContext } from '../../context/Context';
import { useLoading } from '../../context/LoadingContext/LoadingContext';
import { stripePromise } from '../Login/Signup';
import {
  cancelSubscriptionAtPeriodEnd,
  createCheckoutSessionForSubscriptionPlan,
} from '../../api/subscription/subscription';
import showToast from '../../utils/showToast';
import { useNavigate } from 'react-router';



const Pricing = () => {
  const { user } = useContext(AppContext);
  const { setIsLoading } = useLoading();
  const navigate = useNavigate();


  const stripePaymentHandler = async (email, selectedPlan) => {
    setIsLoading(true);

    const stripe = await stripePromise;
    // Request a Checkout session from the backend
    const response = await createCheckoutSessionForSubscriptionPlan({
      email,
      plan: selectedPlan,
    });

    const session = response;

    if (session.error) {
      setIsLoading(false);
      return;
    }

    // Redirect to Stripe Checkout
    const { error } = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (error) {
      console.error('Stripe Checkout Error:', error);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const freemiumPlan = async () => {
    setIsLoading(true);
    await cancelSubscriptionAtPeriodEnd({
      user_id: user?.uid,
      current_plan: user?.FirebaseUser?.plan,
      new_plan: 'Freemium',
    });
    setIsLoading(false);
    showToast(
      'success',
      `Subscription ${user?.FirebaseUser?.plan} downgraded to Freemium successfully and will remain active until the end of your current billing cycle.`
    );

    return;
  };

  const professionalPlan = async () => {
    setIsLoading(true);
    const userEmail = user?.email;
    await stripePaymentHandler(userEmail, 'Professional');
    setIsLoading(false);
    return;
  };

  const contactUsPlan = async () => { };

  return (
    <div className="pricing-container">
      {/* Freemium plan */}
      <div
        className={`pricing-box ${user?.FirebaseUser?.plan === 'Freemium' ? 'pricing-plan-selected' : ''}`}
      >
        <div>
          <h2>Freemium</h2>
          <p className="price">
            £0 <span>/ per month</span>
          </p>
          <ul>
            <li>Unlimited profiles</li>
            <li>Unlimited company profiles</li>
            <li>3 Years Financial Data</li>
            <li>Land & Buildings</li>
            <li>All name matching</li>
            <li>Corporate Networks (Unlimited)</li>
            <li>Save searches Filter</li>
            <li>+ many more...</li>
          </ul>
        </div>
        <button
          className="btn"
          disabled={user?.FirebaseUser?.plan === 'Freemium' ? true : false}
          onClick={freemiumPlan}
        >
          {user?.FirebaseUser?.plan === 'Freemium'
            ? 'Active'
            : 'Get started'}
        </button>
      </div>
      <div
        className={`pricing-box premium ${user?.FirebaseUser?.plan === 'Premium' || user?.FirebaseUser?.plan === 'Professional' ? 'pricing-plan-selected' : ''}`}
      >
        <div>
          <h2>Premium</h2>
          <p className="price">
            £79 <span>/ per month</span>
          </p>
          <ul>
            <li>Everything in Freemium Plus...</li>
            <li>Shareholders</li>
            <li>Charges (Overview)</li>
            <li>Persons of Significant Control</li>
            <li>Export Data</li>
            <li>Save Results</li>
            <li>SIC Codes</li>
            <li>Export networks and entities</li>
            <li>Save searches (unlimited)</li>
            <li>+ many more...</li>
          </ul>
        </div>
        <button
          className="btn"
          disabled={
            user?.FirebaseUser?.plan === 'Professional' ||
              user?.FirebaseUser?.plan === 'Premium'
              ? true
              : false
          }
          onClick={professionalPlan}
        >
          {user?.FirebaseUser?.plan === 'Professional' ||
            user?.FirebaseUser?.plan === 'Premium'
            ? 'Current Plan'
            : 'Get started'}
        </button>
      </div>
      {/* Enterprise plan */}
      <div
        className={`pricing-box enterprise ${user?.FirebaseUser?.plan === 'Enterprise' ? 'pricing-plan-selected' : ''}`}
      >
        <div>
          <h2>Enterprise</h2>
          <p className="price">
            £? <span>/ per month</span>
          </p>
          <ul>
            <li>Everything in Professional...</li>
            <li>Teams Access</li>
            <li>Additional Financial Metrics</li>
            <li>Deep Email Search</li>
            <li>Trademarks</li>
            <li>Deep search connections</li>
            <li>Tax Defaulters Companies</li>
            <li>Annotate Network Graph with notes</li>
            <li>+ many more...</li>
          </ul>
        </div>
        <button
          className="btn"
          disabled={user?.FirebaseUser?.plan === 'Enterprise' ? true : false}
          onClick={() => { navigate('/contact-us '); }}
        >
          {user?.FirebaseUser?.plan === 'Enterprise'
            ? 'Current Plan'
            : 'Contact Us'}
        </button>
      </div>
    </div>
  );
};

export default Pricing;
