import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "./ToolTip.css";

export default function ToolTips() {
  return (
    <div>
      <ReactTooltip
        className="tooltip"
        id="name-tooltip"
        place="top"
        content="Enter entity name here"
      />

      <ReactTooltip
        id="juris-tooltip"
        place="top"
        content="Search entities in United Kingdom"
      />

      <ReactTooltip
        id="disambiguation-tooltip"
        place="top"
        variant="info"
        content="Expand or collapse to select entities"
      />

      <ReactTooltip
        id="disambiguation-item-tooltip"
        place="top"
        variant="info"
        content="Click or tap to select"
      />

      <ReactTooltip
        id="depth-tooltip"
        place="top"
        variant="info"
        content="set depth of network between 1 and 3"
      />

      <ReactTooltip
        id="suggestion-tooltip"
        place="top"
        variant="info"
        content="Submit your feedback and sugestions"
      />

      <ReactTooltip
        id="network-analysis-tooltip"
        place="top-right"
        variant="info"
        content="network analysis"
      />

      <ReactTooltip
        id="download-button-tooltip"
        place="top"
        variant="info"
        content="Download data of selected entity in csv format"
      />

      <ReactTooltip
        id="back-button-tooltip"
        place="top"
        variant="info"
        content="Go back to previous step"
      />

      <ReactTooltip
        id="start-over-button-tooltip"
        place="top"
        variant="info"
        content="Start over from first step"
      />

      <ReactTooltip
        id="help-ticket-tooltip"
        place="top"
        variant="info"
        content="Submit ticket to get help"
      />

      <ReactTooltip
        id="corporate-explorer-filter-button"
        place="top"
        variant="info"
        scrollHide={false}
        resizeHide={false}
        isOpen={true}
        style={{ backgroundColor: "#0078D7" }}
        content={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ fontSize: "16px", fontWeight: "bold" }}>
              Let's Get Started
            </div>
            <div>Click here to add a filter</div>
          </div>
        }
      />
    </div>
  );
}
