import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './UserProfileSideMenu.css';
import SearchIcon from '../../../assets/images/icons/SearchIcon.png';
import ProfileIcon from '../../../assets/images/icons/ProfileIcon.png';
import PasswordIcon from '../../../assets/images/icons/PasswordIcon.png';
import PlanIcon from '../../../assets/images/icons/PlanIcon.png';
import PaymentIcon from '../../../assets/images/icons/PaymentIcon.png';
import SavedIcon from '../../../assets/images/icons/SavedIcon.png';
import HomeIcon from '../../../assets/images/icons/HomeIcon.png';
import ProfileIconLight from '../../../assets/images/icons/ProfileIconLight.png';
import PasswordIconLight from '../../../assets/images/icons/PassIconLight.png';
import PlanIconLight from '../../../assets/images/icons/PlanIconLight.png';
import PaymentIconLight from '../../../assets/images/icons/PaymentIconLight.png';
import SavedIconLight from '../../../assets/images/icons/SavedIconLight.png';
import UserProfileIcon from '../../../assets/images/icons/UserProfileIcon.png';
import doorLogoutIcon from '../../../assets/images/icons/doorLogoutIcon.png';
import { AppContext } from '../../../context/Context';
import { useAuth } from '../../../context/AuthProvider';

const UserProfileSideMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useContext(AppContext);
  const { logout } = useAuth();

  const initialMenuItems = [
    { name: 'Home', icon: HomeIcon, iconLight: HomeIcon, route: '/' },
    {
      name: 'My Profile',
      icon: ProfileIcon,
      iconLight: ProfileIconLight,
      route: '/userProfile',
      isDisabled: false,
    },
    {
      name: 'Password',
      icon: PasswordIcon,
      iconLight: PasswordIconLight,
      route: '/userProfile/updatePassword',
      isDisabled: false,
    },
    {
      name: 'Plan',
      icon: PlanIcon,
      iconLight: PlanIconLight,
      route: '/userProfile/userProfilePlan',
    },
    {
      name: 'Payment',
      icon: PaymentIcon,
      iconLight: PaymentIconLight,
      route: '/userProfile/userProfilePayment',
    },
    {
      name: 'My saved items',
      icon: SavedIcon,
      iconLight: SavedIconLight,
      route: '/userProfile/userProfileSavedItems',
    },
  ];

  const [menuItems, setMenuItems] = useState(initialMenuItems);
  const [activeMenu, setActiveMenu] = useState(() => {
    return (
      menuItems.find(item => location.pathname === item.route)?.name ||
      'My Profile'
    );
  });

  useEffect(() => {
    setActiveMenu(
      menuItems.find(item => location.pathname === item.route)?.name ||
        'My Profile'
    );
  }, [location.pathname]);

  useEffect(() => {
    if (user?.authProvider === 'google.com') {
      setMenuItems(prevMenuItems =>
        prevMenuItems.map(item =>
          item.name === 'Password' ? { ...item, isDisabled: true } : item
        )
      );
    }
  }, [user]);

  const handleMenuClick = item => {
    if (!item.isDisabled) {
      setActiveMenu(item.name);
      navigate(item.route);
    }
  };

  return (
    <div className="user-profile-sidebar">
      {/* Search Container */}
      <div className="user-profile-sidebar-search-container">
        <img
          className="user-profile-sidebar-icon"
          src={SearchIcon}
          alt="Search"
        />
        <input
          type="text"
          className="user-profile-sidebar-search"
          placeholder="Search"
          autoComplete="off"
        />
      </div>

      {/* Menu Items */}
      <ul className="user-profile-sidebar-menu">
        {menuItems.map(item => (
          <>
            {item.isDisabled !== true && (
              <li
                key={item.name}
                className={`user-profile-sidebar-menu-item ${activeMenu === item.name ? 'active' : ''}`}
                onClick={() => handleMenuClick(item)}
              >
                <img
                  className="user-profile-sidebar-icon"
                  src={activeMenu === item.name ? item.iconLight : item.icon}
                  alt={item.name}
                />
                {item.name}
              </li>
            )}
          </>
        ))}
      </ul>

      {/* Profile Section */}
      <div className="user-profile-sidebar-profile">
        <img
          src={user?.photoURL || UserProfileIcon}
          alt={UserProfileIcon}
          className="user-profile-sidebar-profile-img"
        />
        <div className="user-profile-sidebar-profile-info">
          <span className="user-profile-sidebar-profile-name">
            {user?.displayName || 'Name'}
          </span>
          <span className="user-profile-sidebar-profile-email">
            {user?.email || 'User email'}
          </span>
        </div>
        <img
          onClick={logout}
          style={{ height: '17px', width: '17px', cursor: 'pointer' }}
          src={doorLogoutIcon}
          alt="Logout"
        />
      </div>
    </div>
  );
};

export default UserProfileSideMenu;
