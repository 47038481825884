export const apiConfig = {
  base: process.env.REACT_APP_FIREBASE_FUNCTION_ENDPOINT,
  baseDigital: process.env.REACT_APP_FIREBASE_FUNCTION_ENDPOINT_DIGITAL,
  judgementChecker: {
    endpoints: {
      postTrustonlineInfo: '/post_trustonline_info',
      getAddresses: '/get_judgement_addresses',
      saveJudgmentcheckerPdf: '/save_judgementchecker_pdf',
      getUserReports: '/get_user_reports',
      downloadReportFile: '/download_report_file',
      createCheckoutSession: '/create_checkout_session',
      paymentSessionStatus: '/payment_session_status',
      JudgmentCheckerPdfGenerate: '/generatePdf/JudgmentChecker',
      processRefund: '/process_refund',
      raiseNotification: '/raiseNotification',
      getReportSignedUrl: '/get_report_signed_url',
    },
  },
  outseta: {
    endpoints: {
      outsetaUser: '/OutsetaUser',
      getUserById: '/GetUserById',
      getOutsetaUsers: '/GetOutsetaUsers',
      userGaveFeedback: '/userGaveFeedback',
      submitFeedback: '/submitFeedback',
      uniqueViews: '/uniquePageView',
    },
  },
  corporateLinks: {
    endpoints: {
      entitySimilarity: '/entitySimilarity',
      companyInfo: '/companyInfo',
    },
  },
  psc: {
    endpoints: {
      getPscByCompanyNumber: '/getPscByCompanyNumber',
    },
  },
  watchList: {
    endpoints: {
      getCompaniesAndOfficerHistoryForWatchlist:
        '/getCompaniesAndOfficerHistoryForWatchlist',
      getTrackingWatchListByUserId: '/getTrackingWatchListByUserId',
      setWatchListForCompanyByUserId: '/setWatchListForCompanyByUserId',
      deleteWatchListForCompanyByUserId: '/deleteWatchListForCompanyByUserId',
      getWatchListByCompanyNumberAndUserId:
        '/getWatchListByCompanyNumberAndUserId',
    },
  },
  corporateExplorer: {
    endpoints: {
      autoComplete: '/companiesAutoComplete',
      explorerFilter: '/explorerFilter',
      getTotalDocumentsOfIndex: '/getTotalDocumentsOfIndex',
      corporateExplorerCompanyInfo: '/corporateExplorerCompanyInfo', // TODO: mongo company info remove this endpoint from frontend and backend
      corporateExplorerOfficerInfo: '/corporateExplorerOfficerInfoQuickView',
      companiesRegisteredLocationAutoSearch:
        '/companiesRegisteredLocationAutoSearch',
      getCompanyShareholders: '/get_company_shareholders',
      corporateExplorerGetFinancialDocuments:
        '/corporateExplorerGetFinancialDocuments',
      getUseableDocumentUrl: '/getUseableDocumentUrl',
      explorerFilterOfficers: '/explorerFilterOfficers',
      getCompanyTradeMarkByName: '/getCompanyTradeMarkByName',
      getCompanyProperty: '/getCompanyProperty',
      getCompanyInfoByCompanyNumber: '/getCompanyInfoByCompanyNumber', // single company from elasticsearch
      getRelationShips: '/getRelationShips',
      getOfficerInfoByCompanyNumber: '/getOfficerInfoByCompanyNumber',
      getPersonInsights: '/getPersonInsights',
      getNetworkGraphByUserId: '/getNetworkGraphByUserId',
      deleteNetworkGraphByUserIdAndDocumentId:
        '/deleteNetworkGraphByUserIdAndDocumentId',
      getCompaniesOrOfficersByName: '/getCompaniesOrOfficersByName',
      saveNetworkGraphQueryByUserId: '/saveNetworkGraphQueryByUserId',
      getNetworkGraphQueryByUserId: '/getNetworkGraphQueryByUserId',
      getShareholdersOfCompaniesInNetworkGraph:
        '/getShareholdersOfCompaniesInNetworkGraph',
    },
  },
  blogs: {
    endpoints: {
      uploadFile: '/uploadFileAsync',
      submitFeedback: '/submitFeedback',
      addBlogToFirestore: '/addBlogReferenceToFirestore',
      postStarRating: '/starRating',
    },
  },
  helper: {
    endpoints: {
      dummyTrustonline: '/dummyTrustonline',
      sendEmail: '/sendEmail',
    },
  },
  financialAccounts: {
    endpoints: {
      financial_document_parser: process.env.REACT_APP_FIREBASE_FP_ENDPOINT,
      multi_financial_document_parser:
        process.env.REACT_APP_FIREBASE_MI_FP_ENDPOINT,
      getCompaniesFinancialsByCompanyNumber:
        '/getCompaniesFinancialsByCompanyNumber',
    },
  },
  subscription: {
    endpoints: {
      createCheckoutSessionForSubscriptionPlan:
        '/createCheckoutSessionForSubscriptionPlan',
      handleStripeWebhookForSubscriptionPlan:
        '/handleStripeWebhookForSubscriptionPlan',
      deleteUserIfInactive: '/deleteUserIfInactive',
      updateUserProfileByUserId: '/updateUserProfileByUserId',
      cancelSubscriptionAtPeriodEnd: '/cancelSubscriptionAtPeriodEnd',
    },
  },
  helpRequests: {
    endpoints: {
      submitHelpRequest: '/submitHelpRequest',
      submitContactUs: '/enterpriceContactUs',
    },
  },
};

export const buildUrl = (base, endpoint, params = {}, queries = {}) => {
  var url = new URL(`${base}${endpoint}`);

  Object.keys(params).forEach(key => {
    if (params[key] !== undefined) {
      url.searchParams.append(key, params[key]);
    }
  });

  if (Object.keys(queries).length) url += '?';
  Object.keys(queries).forEach(key => {
    if (queries[key] !== undefined) {
      url += `${key}=${queries[key]}&`;
    }
  });
  return url.toString();
};
