import React, { useState } from 'react';
import './CEIinsightResultsCard.css';
import { nationalityToFlag, GradientIcon, statusDivCard } from '../../../utils/helper';
import { getMonthName } from '../../../utils/common';
import PersonIcon from '@mui/icons-material/Person';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LanguageIcon from '@mui/icons-material/Language';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CloseIcon from '@mui/icons-material/Close';

const InsightCard = ({ officer, isSelected, removeItem }) => {
  console.log(officer);
  const [isExpanded, setIsExpanded] = useState(false);

  const isNeedActive = !officer.resigned_on && officer.need >= 20;

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const formatCompanies = officers => {
    if (officers.length === 0) return null;

    if (officers.length === 1) {
      return officers[0]?.company_name; // Only one officer
    } else {
      return (
        <>
          {officers[0]?.company_name}{' '}
          {/* Display only the first officer's name */}
          &nbsp;
          <a href="#">
            & {officers.length - 1} others{' '}
            {/* Display how many others there are */}
          </a>
        </>
      );
    }
  };

  return (
    <div className={`corporate-insights-result-card ${isSelected ? 'InsightCard-selected-card' : ''}`}>
      <div className="corporate-insights-result-card-content">
        <div>
          <div className="corporate-insights-result-card-content-header">
            <div className="corporate-insights-result-card-header-container">
              <div className="corporate-insights-result-card-header-left">
                <GradientIcon IconComponent={PersonIcon} />
                <span className="corporate-insights-result-card-header-name">{officer.name}</span>
                {officer.nationality && (
                  <div className="corporate-insights-result-card-header-flag">
                    {nationalityToFlag[
                      officer?.nationality?.toUpperCase().replace(' ', '')
                    ] && (
                        <img
                          src={
                            nationalityToFlag[
                            officer?.nationality?.toUpperCase().replace(' ', '')
                            ]
                          }
                          alt={`${officer.nationality} flag`}
                          className="corporate-insights-result-card-flag-icon"
                        />
                      )}
                  </div>
                )}
              </div>
              {!removeItem && <div className="corporate-insights-result-card-header-right">
                <GradientIcon IconComponent={LinkedInIcon} />
                <GradientIcon IconComponent={LanguageIcon} />
              </div>
              }
              {removeItem && <div className="corporate-insights-result-card-header-right">
                <CloseIcon onClick={() => removeItem(officer?.title)} style={{ fontSize: 18, color: 'black' }} />
              </div>
              }
            </div>
          </div>
          <div className="corporate-insights-result-card-body">
            {officer.occupation && !isExpanded && (
              <div className="corporate-insights-result-card-info-row">
                <span className="corporate-insights-result-card-value">
                  {officer.occupation}
                </span>
              </div>
            )}
            {officer?.officer_role && !isExpanded && (
              <div className="corporate-insights-result-card-info-row">
                <span className="corporate-insights-result-card-value">
                  {officer?.officer_role}
                </span>
              </div>
            )}
            {officer?.date_of_birth?.month && !isExpanded && officer?.date_of_birth?.year && (
              <div className="corporate-insights-result-card-info-row">
                <span className="corporate-insights-result-card-value">
                  Born in&nbsp;
                  {getMonthName(officer?.date_of_birth?.month)}&nbsp;
                  {officer.date_of_birth.year}
                </span>
              </div>
            )}

            {isExpanded && <div>
              {officer.occupation && (
                <div className='corporate-insights-result-card-row'>
                  <span className="corporate-insights-result-card-info">
                    Occupation
                  </span>
                  <span className="corporate-insights-result-card-info">
                    {officer.occupation}
                  </span>
                </div>
              )}

              {officer.role && (
                <div className='corporate-insights-result-card-row'>
                  <span className="corporate-insights-result-card-info">
                    Role
                  </span>
                  <span className="corporate-insights-result-card-info">
                    {officer.officer_role}
                  </span>
                </div>
              )}

              {officer.company && (
                <div className='corporate-insights-result-card-row'>
                  <span className="corporate-insights-result-card-info">
                    Company
                  </span>
                  <span className="corporate-insights-result-card-info">
                    {officer.role}
                  </span>
                </div>
              )}

              {officer.date_of_birth && (
                <div className='corporate-insights-result-card-row'>
                  <span className="corporate-insights-result-card-info">
                    Date of Birth
                  </span>
                  <span className="corporate-insights-result-card-info">
                    {getMonthName(officer?.date_of_birth?.month)}&nbsp;
                    {officer.date_of_birth.year}
                  </span>
                </div>
              )}

              {officer.nationality && (
                <div className='corporate-insights-result-card-row'>
                  <span className="corporate-insights-result-card-info">
                    Nationality
                  </span>
                  <span className="corporate-insights-result-card-info">
                    {officer.nationality}
                  </span>
                </div>
              )}

              {officer.nationality && (
                <div className='corporate-insights-result-card-row'>
                  <span className="corporate-insights-result-card-info">
                    Officer ID
                  </span>
                  <span className="corporate-insights-result-card-info">
                    {officer.person_number}
                  </span>
                </div>
              )}
            </div>
            }

            {/* {isExpanded && (
            <div className="corporate-insights-result-card-expanded-info">
              <p>Additional information about the officer...</p>
            </div>
          )}
          <div className="corporate-insights-result-card-footer">
            <button className="toggle-button" onClick={handleToggle}>
              {isExpanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </button>
          </div> */}
          </div>
        </div>
        <div className="corporate-insights-result-card-footer">
          <div className="corporate-insights-result-card-info-row">
            <span
              className={`corporate-insights-result-card-value ${isNeedActive
                ? 'corporate-insights-result-card-status-active'
                : 'corporate-insights-result-card-status-inactive'
                }`}
            >
              {officer?.resigned_on ? statusDivCard('resigned') : statusDivCard('active')}
              {isNeedActive && (
                <span className="need-indicator"> (Need 20%)</span>
              )}
            </span>
          </div>
          <button className='corporate-insights-result-card-toggle-button' onClick={handleToggle}>
            {isExpanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </button>
        </div >
      </div>
    </div>

  );
};

export default InsightCard;
