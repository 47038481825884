import axios from 'axios';
import { apiConfig, buildUrl } from '../apiConfig';

export const createCheckoutSessionForSubscriptionPlan = async userData => {
  try {
    const response = await axios.post(
      buildUrl(
        apiConfig.base,
        apiConfig.subscription.endpoints
          .createCheckoutSessionForSubscriptionPlan
      ), // Replace with your actual cloud function URL
      userData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateUserProfileByUserId = async (userData, file) => {
  try {
    const formData = new FormData();
    for (const key in userData) {
      if (userData.hasOwnProperty(key)) {
        formData.append(key, userData[key]);
      }
    }

    if (file) {
      formData.append('profilePicture', file);
    }

    const response = await axios.post(
      buildUrl(
        apiConfig.base,
        apiConfig.subscription.endpoints.updateUserProfileByUserId
      ),
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    // Handle errors
    console.error('Error updating user profile:', error);
    return { success: false, error: error };
  }
};

export const cancelSubscriptionAtPeriodEnd = async userData => {
  try {
    const response = await axios.post(
      buildUrl(
        apiConfig.base,
        apiConfig.subscription.endpoints.cancelSubscriptionAtPeriodEnd
      ), // Replace with your actual cloud function URL
      userData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
