import { useContext, useEffect, useState } from 'react';
import './UserProfilePlan.css';
import FreemiuimIcon from '../../assets/images/icons/FreemiuimIcon.png';
import PremiumIcon from '../../assets/images/icons/PremiumIcon.png';
import BoltIconPurple from '../../assets/images/icons/BoltIconPurple.png';
import { AppContext } from '../../context/Context';
import React from 'react';
import {
  cancelSubscriptionAtPeriodEnd,
  createCheckoutSessionForSubscriptionPlan,
} from '../../api/subscription/subscription';
import { stripePromise } from '../../components/Login/Signup';
import { useLoading } from '../../context/LoadingContext/LoadingContext';
import { useNavigate } from 'react-router-dom';
import showToast from '../../utils/showToast';

const UserProfilePlan = () => {
  const navigate = useNavigate();
  const { setIsLoading } = useLoading();
  const { user } = useContext(AppContext);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [upGradingInfo, setUpGradingInfo] = useState({
    isUpgrading: null,
    currentPlan: null,
    newPlan: null,
  });

  const plans = [
    {
      name: 'Freemium',
      price: '£0',
      description:
        'Includes up to 10 users, 20GB individual data and access to all features.',
      icon: FreemiuimIcon,
    },
    {
      name: 'Professional',
      price: '£79',
      description: 'Everything in Freemium Plan',
      icon: PremiumIcon,
    },
    {
      name: 'Enterprise',
      price: 'Contact Us',
      description: 'Everything Professional',
      icon: BoltIconPurple,
    },
  ];

  const stripePaymentHandler = async (email, selectedPlan) => {
    setIsLoading(true);

    const stripe = await stripePromise;
    // Request a Checkout session from the backend
    const response = await createCheckoutSessionForSubscriptionPlan({
      email,
      plan: selectedPlan,
    });

    const session = response;

    if (session.error) {
      setIsLoading(false);
      return;
    }

    // Redirect to Stripe Checkout
    const { error } = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (error) {
      console.error('Stripe Checkout Error:', error);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  // Function to handle the button click (can disable the button after clicking)
  const proceedToPayment = async () => {
    try {
      // if enterprise then contact us

      if (selectedPlan === 'Enterprise') {
        await contactUs();
      }

      // if free -> professional then payment run
      if (
        upGradingInfo.isUpgrading === true &&
        upGradingInfo.currentPlan === 'Freemium' &&
        upGradingInfo.newPlan === 'Professional'
      ) {
        setIsLoading(true);
        const userEmail = user?.email;
        await stripePaymentHandler(userEmail, selectedPlan);
        setIsLoading(false);
        return;
      }

      // if professional -> free then downgrade api
      if (
        upGradingInfo.isUpgrading === false &&
        upGradingInfo.currentPlan === 'Professional' &&
        upGradingInfo.newPlan === 'Freemium'
      ) {
        setIsLoading(true);
        await cancelSubscriptionAtPeriodEnd({
          user_id: user?.uid,
          current_plan: upGradingInfo.currentPlan,
          new_plan: upGradingInfo.newPlan,
        });
        setIsLoading(false);
        showToast(
          'success',
          `Subscription ${user?.FirebaseUser?.plan} downgraded to Freemium successfully and will remain active until the end of your current billing cycle.`
        );
        return;
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const contactUs = () => {
    navigate('/contact-us');
  };

  let priceButtonContant = () => {
    if (selectedPlan === 'Enterprise') {
      return <>Contact Us</>;
    }

    if (selectedPlan === 'Freemium') {
      return <>Continue Freemium</>;
    }

    return <>Proceed to payment</>;
  };

  useEffect(() => {
    if (
      user?.FirebaseUser?.plan === 'Freemium' &&
      selectedPlan === 'Professional'
    ) {
      setUpGradingInfo({
        isUpgrading: true,
        currentPlan: user?.FirebaseUser?.plan,
        newPlan: selectedPlan,
      });
    }

    if (
      user?.FirebaseUser?.plan === 'Professional' &&
      selectedPlan === 'Freemium'
    ) {
      setUpGradingInfo({
        isUpgrading: false,
        currentPlan: user?.FirebaseUser?.plan,
        newPlan: selectedPlan,
      });
    }

    if (selectedPlan === user?.FirebaseUser?.plan) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [selectedPlan]);

  useEffect(() => {
    if (user) {
      setSelectedPlan(user?.FirebaseUser?.plan);
    }
  }, [user]);

  useEffect(() => {
    console.log('upGradingInfo', upGradingInfo);
  }, [upGradingInfo]);

  return (
    <div className="up-container">
      <div className="up-header">
        <div>
          <h2 className="user-profile-dashboard-title">Plan</h2>
          <p style={{ fontWeight: 'normal', fontSize: '13px' }}>
            Manage your plan and Payment here..
          </p>
        </div>
      </div>
      <hr style={{ marginTop: '0' }} />

      <div className="user-plan-pricing-container">
        {plans.map(plan => (
          <div
            key={plan.name}
            className={`user-plan-card ${selectedPlan === plan.name ? 'selected' : ''}`}
            onClick={() => setSelectedPlan(plan.name)}
          >
            <div className="user-plan-header">
              <span className="user-plan-icon">
                <img src={plan.icon} alt="icon" />
              </span>
              <span className="user-plan-title">{plan.name}</span>
              <span className="user-plan-radio">
                {selectedPlan === plan.name && '✔'}
              </span>
            </div>
            <hr
              className={`user-plan-hr ${selectedPlan === plan.name ? 'selected' : ''}`}
            />
            <div className="user-plan-price">
              {plan.price}{' '}
              {plan.price !== 'Contact Us' && <span>per month</span>}
            </div>
            <div className="user-plan-description">{plan.description}</div>
          </div>
        ))}
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '20px',
        }}
      >
        <div
          onClick={selectedPlan === 'Enterprise' ? contactUs : proceedToPayment}
          style={{
            backgroundColor: isDisabled ? '#b0b0b0' : '#00D1A9',
            color: isDisabled ? '#757575' : 'white',
            padding: '10px 20px',
            borderRadius: '5px',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            fontSize: '12px',
            width: '169px',
            textAlign: 'center',
            fontWeight: 'bold',
            boxSizing: 'border-box',
            transition: 'background-color 0.3s ease',
          }}
        >
          {priceButtonContant()}
        </div>
      </div>
    </div>
  );
};

export default UserProfilePlan;
