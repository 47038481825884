import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import showToast from '../../utils/showToast';
import { useAuth } from '../../context/AuthProvider';

function PaymentCancel() {
  const navigate = useNavigate();
  const { logout } = useAuth();

  useEffect(() => {
    // Show a toast message for payment cancellation
    showToast('Payment Cancelled');

    // Wait for 3 seconds before logging out and navigating
    const timer = setTimeout(async () => {
      try {
        navigate('/auth'); // Redirect to the homepage
      } catch (error) {
        console.error('Error during logout:', error);
      }
    }, 3000);

    // Cleanup the timer on component unmount
    return () => clearTimeout(timer);
  }, [navigate, logout]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#fff5f5',
        fontFamily: 'Arial, sans-serif',
        textAlign: 'center',
      }}
    >
      <h1 style={{ color: '#f44336', marginBottom: '20px' }}>
        Payment Cancelled
      </h1>
      <p style={{ color: '#555', marginBottom: '30px' }}>
        Your payment was not completed. If this was a mistake, please try again.
      </p>
      <p style={{ color: '#555', marginTop: '20px' }}>
        Redirecting to the Dashboard...
      </p>
    </div>
  );
}

export default PaymentCancel;
