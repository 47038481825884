import './GetCodeScreen.css';
import fingerPrintLogo from '../../assets/images/icons/fingerPrintLogo.png';

const GetCodeScreen = () => {
  const handleForgetConfirm = () => {};

  return (
    <div className="probe-login-container">
      <div className="probe-login-container-inner-div">
        <div className="probe-login-container-logo-div">
          <img
            className="probe-login-container-logo"
            src={fingerPrintLogo}
            alt="Logo"
          />
        </div>
        <h2 className="probe-login-title">Get Your Code</h2>
        <p
          style={{ textAlign: 'center', fontSize: '12px', lineHeight: '18px' }}
        >
          Please enter a 4 digit code that sent to your email address
        </p>

        <div className="code-input-container">
          <input
            type="text"
            maxLength="1"
            placeholder="0"
            className="code-input"
          />
          <input
            type="text"
            maxLength="1"
            placeholder="0"
            className="code-input"
          />
          <input
            type="text"
            maxLength="1"
            placeholder="0"
            className="code-input"
          />
          <input
            type="text"
            maxLength="1"
            placeholder="0"
            className="code-input"
          />
        </div>

        <button onClick={handleForgetConfirm}>Verify and proceed</button>
      </div>
    </div>
  );
};

export default GetCodeScreen;
