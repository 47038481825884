import './HeaderDropdown.css';
import './HeaderDropdownNative.css';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileAvatar from '../assets/images/icons/avatar-demo.png';
import MyProfileIcon from '../assets/images/icons/my-profile-icon.svg';
import CustomerAdminIcon from '../assets/images/icons/customer-admin-icon.svg';
import SuggestionIcon from '../assets/images/icons/bx_chat.svg';
import { AppContext } from '../context/Context';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';
import boltIcon from '../assets/images/icons/boltIcon.png';
import HomeIcon from '../assets/images/icons/HomeIcon.png';
import doorLogoutIcon from '../assets/images/icons/doorLogoutIcon.png';
import boxIcon from '../assets/images/icons/boxIcon.png';
import personIcon from '../assets/images/icons/personIcon.png';
import UserProfileIcon from '../assets/images/icons/UserProfileIcon.png';
import crownIcon from '../assets/images/icons/crownIcon.png';

function HeaderDropdown() {
  const location = useLocation();
  const { user, isAuthModelOpen } = useContext(AppContext);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [toggleDropDown, setToggleDropDown] = useState(false);

  const loginUser = () => {
    setToggleDropDown(false);
  };

  const logoutUser = () => {
    logout();
    setToggleDropDown(false); // Close the dropdown after logout
  };

  const handleSubmitRequest = () => {
    navigate('/help-request');
  };

  const handleMenuClick = path => {
    navigate(path);
    setToggleDropDown(false); // Close the dropdown when a menu item is clicked
  };

  useEffect(() => {
    // Close the dropdown when the location changes (if necessary)
    setToggleDropDown(false);
  }, [location]);

  return (
    <div className="name-div">
      {!location.pathname === '/' && location.pathname !== '/home' && (
        <div
          className="make-a-suggestion-div"
          data-tooltip-id="suggestion-tooltip"
        >
          <button
            onClick={() => {
              navigate('/fbf-dashboard/feedbackform');
            }}
            className="body-text-normal"
          >
            <img
              style={{ marginRight: '5px' }}
              src={SuggestionIcon}
              alt="icon here"
            />
            <span className="help-text-large">Make a Suggestion</span>
            <span className="help-text-small">Suggestion</span>
          </button>
        </div>
      )}
      <div data-tooltip-id="help-request-tooltip">
        <button
          className="custom-gradient-button"
          onClick={handleSubmitRequest}
        >
          Submit Help Request
        </button>
      </div>
      <div className="user-plan-button" data-tooltip-id="User-plan-tooltip">
        {user?.FirebaseUser?.plan === 'Professional' ? (
          <img src={crownIcon} alt="premium-icon" />
        ) : (
          <img src={boltIcon} alt="premium-icon" />
        )}
        <button
          style={{
            color:
              user?.FirebaseUser?.plan === 'Professional'
                ? 'rgb(2, 156, 189)'
                : '#000',
            border: 'none',
            backgroundColor: 'transparent',
          }}
          onClick={() => navigate('/userProfile/userProfilePlan')}
        >
          {user?.FirebaseUser?.plan === 'Professional'
            ? 'Premium'
            : 'Upgrade Now'}
        </button>
      </div>
      {/* Dropdown Menu */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className="dropdown">
          <div
            className="btn dropdown-toggle header-dropdown-native-userdropdown"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onClick={() => setToggleDropDown(!toggleDropDown)}
          >
            {user && !user?.guest && !isAuthModelOpen ? (
              <div
                className="header-dropdown-native-userdropdown-initials"
                data-tooltip-id="profile-tooltip"
              >
                <img
                  src={user.photoURL || ProfileAvatar}
                  alt="Profile Avatar"
                  referrerPolicy="no-referrer"
                  width={50}
                  height={50}
                />
              </div>
            ) : null}

            {user && !user?.guest && !isAuthModelOpen ? (
              <div
                className="name-greetings dark-heading-color"
                data-tooltip-id="profile-tooltip"
              >
                Hi {user.displayName}
              </div>
            ) : null}

            {!user || user?.guest ? (
              <div
                style={{ display: 'flex', gap: '10px', alignItems: 'center' }}
              >
                <img
                  className="greetings-avatar"
                  src="https://s3-alpha-sig.figma.com/img/0c26/d887/ed060b47018885c4c6847048f8a83758?Expires=1736726400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=HvAUPyAOtA03TnIzknV9VY69lXoY8ivZcfivWDyDBfC0IOXkIX8ywDCMeKryb0N4COvQeRFVmd4BkAOEpnB~BsN3jGP28sYg5qyXkW4HmroAEVAA6AZqP8zV34AUvGkcl79rD80p4FVSI0B-70xV~qw2fDVxnwov1fNwDnlh4KcvONZTYK8Ro3qFyUTifgn3ZRLrw0MFECV9kXG60733uqmLic-pIk7ogcbSV~CkjEkATTsGHKjnRyzmKuavmFSdGevbUwna2C10akzMNiH~CUdkznBWJiGA4qUjw6PDTAhv8K6eFJARBnHrs5m3Oxws1TI6VQEgenk9Htj1v5BFHw__"
                  alt="IMG"
                />
                <div className="name-greetings dark-heading-color">
                  Guest User
                </div>
              </div>
            ) : null}
          </div>

          {user && !user?.guest ? (
            <ul className={`dropdown-menu`}>
              {/* Profile Section */}
              <div className="dropdown-profile">
                <img
                  src={user?.photoURL || UserProfileIcon}
                  alt={UserProfileIcon}
                  className="profile-img"
                />
                <div className="profile-info">
                  <span className="profile-name">
                    {user?.displayName || 'Name'}
                  </span>
                  <span className="profile-email">
                    {user?.email || 'User email'}
                  </span>
                </div>
              </div>

              <div
                className="dropdown-item"
                onClick={() => handleMenuClick('/userProfile')}
              >
                <li>
                  <div>
                    <img
                      style={{ height: '15px', width: '14px' }}
                      src={personIcon}
                      alt="icon here"
                    />
                  </div>
                  <div className="dropdown-item-text">View Profile</div>
                </li>
              </div>

              <div
                className="dropdown-item"
                onClick={() => handleMenuClick('/userProfile/userProfilePlan')}
              >
                <li>
                  <div>
                    <img
                      style={{ height: '17px', width: '15px' }}
                      src={HomeIcon}
                      alt="icon here"
                    />
                  </div>
                  <div
                    className="dropdown-item-text"
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span>Plan</span>
                    <span style={{ color: '#0466D1', fontSize: '12px' }}>
                      {user ? user?.FirebaseUser?.plan : "User's Plan"}
                    </span>
                  </div>
                </li>
              </div>

              <div
                className="dropdown-item"
                onClick={() =>
                  handleMenuClick('/userProfile/userProfilePayment')
                }
              >
                <li>
                  <div>
                    <img
                      style={{ height: '19px', width: '15px' }}
                      src={boxIcon}
                      alt="icon here"
                    />
                  </div>
                  <div className="dropdown-item-text">Transactions</div>
                </li>
              </div>

              {user?.FirebaseUser?.role === 'admin' && (
                <div
                  className="dropdown-item"
                  onClick={() => handleMenuClick('/profile/customer-admin')}
                >
                  <li>
                    <div>
                      <img
                        style={{ height: '19px', width: '13px' }}
                        src={CustomerAdminIcon}
                        alt="icon here"
                      />
                    </div>
                    <div className="dropdown-item-text">
                      Customer Administration
                    </div>
                  </li>
                </div>
              )}

              <div
                className="dropdown-item"
                onClick={() =>
                  handleMenuClick('/userProfile/userProfileSavedItems')
                }
              >
                <li>
                  <div>
                    <img
                      style={{ height: '19px', width: '13px' }}
                      src={boltIcon}
                      alt="report"
                    />
                  </div>
                  <div className="dropdown-item-text">My Saved Items</div>
                </li>
              </div>
              {/* 
              <div
                className="dropdown-item"
                onClick={() => handleMenuClick('/profile/accounts-usage')}
              >
                <li>
                  <div>
                    <img src={Settings} alt="icon here" />
                  </div>
                  <div className="dropdown-item-text">Usage</div>
                </li>
              </div> */}

              <div
                className="dropdown-item"
                onClick={logoutUser}
                style={{ borderTop: '1px solid #e0e0e0' }}
              >
                <li>
                  <div>
                    <img
                      style={{ height: '15px', width: '15px' }}
                      src={doorLogoutIcon}
                      alt="icon here"
                    />
                  </div>
                  <div className="dropdown-item-text">Logout</div>
                </li>
              </div>
            </ul>
          ) : null}

          {!user || user?.guest ? (
            <ul className="dropdown-menu">
              <div className="dropdown-item">
                <li onClick={loginUser}>
                  <div>
                    <img src={MyProfileIcon} alt="icon here" />
                  </div>
                  <div>Login</div>
                </li>
              </div>
            </ul>
          ) : null}
        </div>
      </div>{' '}
    </div>
  );
}

export default HeaderDropdown;
