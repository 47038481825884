import React, { useContext, useEffect, useState } from 'react';
import './HelpRequest.css';
import MainFooter from '../../components/MainFooter/footer';
import { AppContext } from '../../context/Context';
import { apiConfig, buildUrl } from '../../api/apiConfig';
import axios from 'axios';
import { useLoading } from '../../context/LoadingContext/LoadingContext';

const HelpRequest = () => {
  const { user } = useContext(AppContext);
  const [userId, setUserId] = useState('');
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    services: {
      corporate_link: false,
      corporate_search: false,
      corporate_profile: false,
      judgement_checker: false,
      other: false,
    },
    message: '',
  });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const { setIsLoading } = useLoading();

  useEffect(() => {
    setUserId(user?.uid || '');
  }, [user]);

  const handleChange = event => {
    const { name, value, type, checked } = event.target;
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: '',
    }));
    if (type === 'checkbox') {
      setFormData(prevState => ({
        ...prevState,
        services: {
          ...prevState.services,
          [name]: checked,
        },
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.first_name) newErrors.first_name = 'First name is required.';
    if (!formData.last_name) newErrors.last_name = 'Last name is required.';
    if (!formData.email) {
      newErrors.email = 'Email is required.';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email address is invalid.';
    }
    if (!formData.message) newErrors.message = 'Message is required.';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (!validateForm()) return;

    const payload = {
      user_id: userId,
      ...formData,
    };
    try {
      setIsLoading(true);
      const response = await axios.post(
        buildUrl(
          apiConfig.base,
          apiConfig.helpRequests.endpoints.submitHelpRequest
        ),
        payload
      );

      // Set success message and clear it after 2 seconds
      setSuccessMessage('Your request has been submitted successfully!');
      setTimeout(() => setSuccessMessage(''), 2000);

      // Reset form fields after successful submission
      setFormData({
        first_name: '',
        last_name: '',
        email: '',
        services: {
          corporate_link: false,
          corporate_search: false,
          corporate_profile: false,
          judgement_checker: false,
          other: false,
        },
        message: '',
      });
      setIsLoading(false);
      return response.data;
    } catch (error) {
      setIsLoading(false);
      return { success: false, error: error.message };
    }
  };

  return (
    <div>
      <div className="help-request-container">
        <div className="help-request-left">
          <h1>
            Have a Question?
            <br />
            Need a Solution?
            <br />
            We're Here to Help!
          </h1>
        </div>

        <div className="help-request-right">
          <h2 className="help-request-heading">
            Fill out the form below, and we'll get back to you in no time.
          </h2>
          <form className="help-request-form" onSubmit={handleSubmit}>
            <div className="help-request-form-group">
              <div>
                <label className="help-request-label">
                  First Name<span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="text"
                  name="first_name"
                  placeholder="First name"
                  className="help-request-input"
                  value={formData.first_name}
                  onChange={handleChange}
                />
                {errors.first_name && (
                  <p style={{ color: 'red' }}>{errors.first_name}</p>
                )}
              </div>
              <div>
                <label className="help-request-label">
                  Last Name<span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="text"
                  name="last_name"
                  placeholder="Last name"
                  className="help-request-input"
                  value={formData.last_name}
                  onChange={handleChange}
                />
                {errors.last_name && (
                  <p style={{ color: 'red' }}>{errors.last_name}</p>
                )}
              </div>
            </div>
            <label className="help-request-label">
              Email<span style={{ color: 'red' }}>*</span>
            </label>
            <input
              type="text"
              name="email"
              placeholder="you@company.com"
              className="help-request-input"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}

            <label className="help-request-label">
              Services<span style={{ color: 'red' }}>*</span>
            </label>
            <div className="help-request-services">
              {Object.keys(formData.services).map(service => (
                <label key={service} className="help-request-checkbox">
                  <input
                    type="checkbox"
                    name={service}
                    checked={formData.services[service]}
                    onChange={handleChange}
                  />{' '}
                  {service.replace('_', ' ')}
                </label>
              ))}
            </div>

            <label className="help-request-label">
              Message<span style={{ color: 'red' }}>*</span>
            </label>
            <textarea
              name="message"
              placeholder="Leave us a message..."
              className="help-request-textarea"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
            {errors.message && <p style={{ color: 'red' }}>{errors.message}</p>}
            <button type="submit" className="help-request-button">
              Submit Here
            </button>
          </form>
          {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
        </div>
      </div>
      <MainFooter />
    </div>
  );
};

export default HelpRequest;
