import React, { useContext, useEffect, useState } from "react";
import "./ContactUs.css";
import MainFooter from '../../components/MainFooter/footer';
import { AppContext } from "../../context/Context";
import axios from "axios";
import { apiConfig, buildUrl } from "../../api/apiConfig";
import { useLoading } from '../../context/LoadingContext/LoadingContext';


const ContactUs = () => {
    const { user } = useContext(AppContext);
    const [userId, setUserId] = useState("");
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        message: ""
    });
    const { setIsLoading } = useLoading();
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState("");

    useEffect(() => {
        setUserId(user?.uid || "");
    }, [user]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: ""
        }));
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.first_name) newErrors.first_name = "First name is required.";
        if (!formData.last_name) newErrors.last_name = "Last name is required.";
        if (!formData.email) {
            newErrors.email = "Email is required.";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = "Email address is invalid.";
        }
        if (!formData.message) newErrors.message = "Message is required.";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateForm()) return;

        const payload = {
            user_id: userId,
            ...formData,
            sent_to: [
                "nazim@probedigital.co.uk",
                "sam@probedigital.co.uk",
                "uzair@probedigital.co.uk"
            ]
        };
        console.log(payload, "PAYLOAD")

        try {
            setIsLoading(true);
            const response = await axios.post(
                buildUrl(apiConfig.base, apiConfig.helpRequests.endpoints.submitContactUs),
                payload
            );
            console.log(response.data, "Ss")

            // Show success message
            setSuccessMessage("Your message has been sent successfully!");
            setTimeout(() => setSuccessMessage(""), 2000); // Clear message after 2 seconds

            // Reset form fields after successful submission
            setFormData({
                first_name: "",
                last_name: "",
                email: "",
                message: ""
            });
            setIsLoading(false);
            return response.data;
        } catch (error) {
            setIsLoading(false);
            return { success: false, error: error.message };
        }
    };

    return (
        <div>
            <div className="contact-us-container">
                <div className="contact-us-left">
                    <h1>
                        Searching for the right enterprise solution? <br />
                        Reach out to us!
                    </h1>
                </div>

                <div className="contact-us-right">
                    <h2 className="contact-us-heading">
                        Fill out the form below, and we'll get back to you in no time.
                    </h2>
                    <form className="contact-us-form" onSubmit={handleSubmit}>
                        <div className="contact-us-form-group">
                            <div>
                                <label className="contact-us-label">First Name<span style={{ color: "red" }}>*</span></label>
                                <input
                                    type="text"
                                    name="first_name"
                                    placeholder="First name"
                                    className="contact-us-input"
                                    value={formData.first_name}
                                    onChange={handleChange}
                                />
                                {errors.first_name && <p style={{ color: "red" }}>{errors.first_name}</p>}
                            </div>
                            <div>
                                <label className="contact-us-label">Last Name<span style={{ color: "red" }}>*</span></label>
                                <input
                                    type="text"
                                    name="last_name"
                                    placeholder="Last name"
                                    className="contact-us-input"
                                    value={formData.last_name}
                                    onChange={handleChange}
                                />
                                {errors.last_name && <p style={{ color: "red" }}>{errors.last_name}</p>}
                            </div>
                        </div>
                        <label className="contact-us-label">Email<span style={{ color: "red" }}>*</span></label>
                        <input
                            type="email"
                            name="email"
                            placeholder="you@company.com"
                            className="contact-us-input"
                            value={formData.email}
                            onChange={handleChange}
                        />
                        {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}

                        <label className="contact-us-label">Message<span style={{ color: "red" }}>*</span></label>
                        <textarea
                            name="message"
                            placeholder="Leave us a message..."
                            className="contact-us-textarea"
                            value={formData.message}
                            onChange={handleChange}
                        ></textarea>
                        {errors.message && <p style={{ color: "red" }}>{errors.message}</p>}
                        <button type="submit" className="contact-us-button">
                            Submit Here
                        </button>
                    </form>
                    {successMessage && <p style={{ color: "green" }}>{successMessage}</p>}
                </div>
            </div>
            <MainFooter />
        </div>
    );
};

export default ContactUs;
