import React, { useEffect, useState } from 'react';
import LineHeader from '../../assets/images/icons/header-line.svg';
import userIcon from '../../assets/images/icons/User.svg';
import surnameIcon from '../../assets/images/icons/Badge.svg';
import PhoneIcon from '../../assets/images/icons/Phone.svg';
import EmailIcon from '../../assets/images/icons/Email.svg';
import OrganizationIcon from '../../assets/images/icons/Organization.svg';
import CountryIcon from '../../assets/images/icons/Jurisdication.svg';
import TableLoadingScreen from '../../components/LoadingScreen/TableLoadingScreen/TableLoadingScreen';
import './WatchlistTable.css';

function WatchlistTable({
  trackingWatchList,
  trackingWatchListOnClick,
  searchQuery,
  debouncedSearch,
  isSearchInProgress,
}) {
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const onRowClick = (customer, index) => {
    trackingWatchListOnClick(customer);
    setSelectedRowIndex(index);
  };

  return (
    <div className="customerTable-info-section">
      <div className="header-section">
        <div className="body-text-normal dark-text-color">
          <img src={LineHeader} alt="icon here" /> Watchlist
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-custom table-hover">
          <thead>
            <tr>
              <td scope="col">
                <div className="thead-td-custom">
                  <img
                    className="img-thead"
                    src={surnameIcon}
                    alt="icon here"
                  />{' '}
                  Name{' '}
                </div>
              </td>
              <td scope="col">
                <div className="thead-td-custom">
                  <img className="img-thead" src={userIcon} alt="icon here" />{' '}
                  Status{' '}
                </div>
              </td>
              <td scope="col">
                <div className="thead-td-custom">
                  <img
                    className="img-thead"
                    src={OrganizationIcon}
                    alt="icon here"
                  />{' '}
                  Relation{' '}
                </div>
              </td>
              <td scope="col">
                <div className="thead-td-custom">
                  <img className="img-thead" src={EmailIcon} alt="icon here" />{' '}
                  Latest Activity{' '}
                </div>
              </td>
              <td scope="col">
                <div className="thead-td-custom">Delete</div>
              </td>
            </tr>
          </thead>
          <tbody>
            {isSearchInProgress && <TableLoadingScreen />}
            {trackingWatchList?.length > 0 && !isSearchInProgress ? (
              trackingWatchList?.map((tracking, index) => (
                <tr
                  key={index}
                  style={{
                    '--bs-table-bg':
                      index === selectedRowIndex ? '#EEEDEB' : '',
                  }}
                >
                  <>
                    <td className="body-text-bold">
                      {tracking?.tracking?.company_name}
                    </td>
                    <td className="body-text-bold">
                      {tracking?.tracking?.company_status}
                    </td>
                    <td className="body-text-bold">{tracking.relationship}</td>
                    <td className="body-text-bold">
                      {tracking?.tracking?.message}
                      <div style={{ display: 'flex', gap: '2px' }}>
                        <div>{tracking?.tracking?.updatedOn?.timeString}</div>
                        <div>
                          {tracking?.tracking?.updatedOn?.formattedDate}
                        </div>
                      </div>
                    </td>
                    <td
                      className="body-text-bold"
                      style={{ cursor: 'pointer' }}
                      onClick={() => onRowClick(tracking, index)}
                    >
                      x
                    </td>
                  </>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="body-text-bold">
                  {!isSearchInProgress && <>No data found</>}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default WatchlistTable;
