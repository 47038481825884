import React, { useState, useContext, useEffect } from 'react';
import './CESAdvancedFilterPerson.css';
import DateRangeSlider from '../CESAdvancedFilter/CESFilterSlider/CESFilterSlider';
import { AppContext } from '../../../../context/Context';
import ProbeButton from '../../../../components/ProbeButton/ProbeButton';

const CESAdvancedFilterPerson = ({ inputValue, setInputValue }) => {
  const minDate = 1800;
  const maxDate = 2024;
  const { corporateLinksAdvanceFilters, setCorporateLinksAdvanceFilters } =
    useContext(AppContext);

  // Temporary state for filters
  const [tempFilterPerson, setTempFilterPerson] = useState({
    nationality: '',
    occupation: '',
    positionRole: '',
    address: '',
    postal_code: '',
    dateRange: [minDate, maxDate],
  });

  const [isSearchEnabled, setIsSearchEnabled] = useState(false);

  const [advanceFilterPerson, setAdvanceFilterPerson] = useState({
    nationality: '',
    occupation: '',
    positionRole: '',
    address: '',
    postal_code: '',
    dateRange: [minDate, maxDate],
  });

  // Update temp filters
  const handleTempChange = e => {
    const { name, value } = e.target;

    // Update the tempFilterPerson state
    setTempFilterPerson(prevFilters => ({
      ...prevFilters,
      [name]: value,
    }));

    // If the input is for the "name" field, update the inputValue state
    if (name === 'name') {
      setInputValue(value);
    }
  };

  const handleTempDateRangeChange = newRange => {
    setTempFilterPerson({
      ...tempFilterPerson,
      dateRange: newRange,
    });
  };

  const applyFilters = () => {
    const filteredTempFilterPerson = {
      ...tempFilterPerson,
      occupation:
        tempFilterPerson.occupation === 'Occupation'
          ? ''
          : tempFilterPerson.occupation,
      nationality:
        tempFilterPerson.nationality === 'Nationality'
          ? ''
          : tempFilterPerson.nationality,
      positionRole:
        tempFilterPerson.positionRole === 'Officer Role'
          ? ''
          : tempFilterPerson.positionRole,
      address: tempFilterPerson.address || '',
      postal_code: tempFilterPerson.postal_code || '',
    };

    console.log("APPLIED FILTERS: ", filteredTempFilterPerson);

    // Apply the filtered values
    setAdvanceFilterPerson(filteredTempFilterPerson);
    setCorporateLinksAdvanceFilters({
      ...corporateLinksAdvanceFilters,
      officerFilters: filteredTempFilterPerson,
    });
  };

  useEffect(() => {
    const isAnyFieldFilled =
      tempFilterPerson.name ||
      tempFilterPerson.nationality ||
      tempFilterPerson.occupation ||
      tempFilterPerson.positionRole ||
      tempFilterPerson.address ||
      tempFilterPerson.postal_code ||
      tempFilterPerson.dateRange[0] !== minDate ||
      tempFilterPerson.dateRange[1] !== maxDate;
    setIsSearchEnabled(isAnyFieldFilled);
  }, [tempFilterPerson]);

  return (
    <div>
      <div className="company-person-advance-filter-row">
        <div className="company-person-advance-filter-col">
          <label htmlFor="name" className="form-label filter-label">
            Person Name
          </label>
          <input
            className="form-control company-person-advance-filter-form-control"
            id="name"
            placeholder="Fullname"
            name="name"
            value={inputValue}
            onChange={handleTempChange}
            disabled
          />
        </div>
        <div className="company-person-advance-filter-col">
          <label htmlFor="occupation" className="form-label filter-label">
            Occupation
          </label>
          <select
            className="form-control company-person-advance-filter-form-control"
            id="occupation"
            name="occupation"
            value={tempFilterPerson.occupation}
            onChange={handleTempChange}
          >
            <option>Occupation</option>
            <option>BUSINESS PERSON</option>
            <option>MATHEMATICIAN</option>
            <option>OPERATIONS MANAGER</option>
            <option>COMPANY DIRECTOR</option>
            <option>DIRECTOR</option>
            <option>CONSULTANT</option>
            <option>Nurse</option>
          </select>
        </div>
        <div className="company-person-advance-filter-col">
          <label htmlFor="positionRole" className="form-label filter-label">
            Officer Role
          </label>
          <select
            className="form-control company-person-advance-filter-form-control"
            id="positionRole"
            name="positionRole"
            value={tempFilterPerson.positionRole}
            onChange={handleTempChange}
          >
            <option>Officer Role</option>
            <option>Current Director</option>
          </select>
        </div>
      </div>

      <div className="company-person-advance-filter-row">
        <div className="company-person-advance-filter-col">
          <label htmlFor="address" className="form-label filter-label">
            Address
          </label>
          <input
            className="form-control company-person-advance-filter-form-control"
            id="address"
            placeholder="Address"
            name="address"
            value={tempFilterPerson.address}
            onChange={handleTempChange}
          />
        </div>
        <div className="company-person-advance-filter-col">
          <label htmlFor="nationality" className="form-label filter-label">
            Nationality:
          </label>
          <select
            className="form-control company-person-advance-filter-form-control"
            id="nationality"
            name="nationality"
            value={tempFilterPerson.nationality}
            onChange={handleTempChange}
          >
            <option>Nationality</option>
            <option>England</option>
            <option>British</option>
            <option>AFGHAN</option>
            <option>ROMANIAN</option>
            <option>ENGLISH</option>
            <option>Pakistani</option>
            <option>UKRAINIAN</option>
          </select>
        </div>
        <div className="company-person-advance-filter-col">
          <label htmlFor="postal_code" className="form-label filter-label">
            Postal Code
          </label>
          <input
            className="form-control company-person-advance-filter-form-control"
            id="postal_code"
            placeholder="Post Code"
            name="postal_code"
            value={tempFilterPerson.postal_code}
            onChange={handleTempChange}
          />
        </div>
      </div>
      <div className="company-person-advance-filter-col">
        <div>
          <DateRangeSlider
            labelName={'Age'}
            values={tempFilterPerson.dateRange}
            setValues={handleTempDateRangeChange}
            minDate={minDate}
            maxDate={maxDate}
          />
        </div>
      </div>

      <div className="col-md-12 mt-3">
        <div className="person-filter-search">
          <ProbeButton
            width="343px"
            height="40px"
            name="Search"
            borderRadius="24px"
            backgroundColor={
              isSearchEnabled
                ? 'linear-gradient(to right, #0466D1, #00D1A9)'
                : '#8A8A8A'
            }
            fontColor="white"
            borderColor={isSearchEnabled ? '' : '#8A8A8A'}
            disabled={isSearchEnabled ? false : true}
            onClick={applyFilters}
          />
        </div>
      </div>
    </div>
  );
};

export default CESAdvancedFilterPerson;
