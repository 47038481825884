import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
} from 'firebase/auth';
import { firebaseAuth } from '../../context/firebaseContext/firebase';
import { createUser } from '../../utils/helper';

export const signupWithEmail = async (email, password, name, selectedPlan) => {
  try {
    try {
      const result = await signInWithEmailAndPassword(
        firebaseAuth,
        email,
        password
      );
      const existingUser = result.user;
      return {
        success: false,
        error: 'User already exists with this email',
        code: 'auth/email-already-in-use',
        uid: existingUser.uid, // Return the uid of the existing user
      };
    } catch (signInError) {
      if (signInError.code !== 'auth/user-not-found') {
        throw signInError; // Rethrow other errors
      }
      // If user is not found, we proceed with signup
    }

    // Create user with email and password
    const result = await createUserWithEmailAndPassword(
      firebaseAuth,
      email,
      password
    );

    // Get the user object
    const user = result.user;

    // Update the user profile
    if (user) {
      await updateProfile(user, {
        displayName: name,
      });
    }

    console.log('signupWithEmail', user);

    // Create or map user data
    let createdUser = await createUser(user?.uid, user?.email, selectedPlan);
    return { success: true, user, firebaseUser: createdUser };
  } catch (error) {
    if (error.code === 'auth/email-already-in-use') {
      console.error('User already exists');
      return {
        success: false,
        error: 'User already exists with this email',
        code: error.code,
      };
    }

    console.error('Error during signup', error);
    return {
      success: false,
      error: error.message,
      code: error.code,
    };
  }
};
