import "./UserProfilePayment.css";
import React, { useState } from "react";
import CloudIcon from '../../assets/images/icons/CloudIcon.png'

const UserProfilePayment = () => {
    const [selectedCard, setSelectedCard] = useState("Visa");
    const [selectedInvoices, setSelectedInvoices] = useState([]);

    const cards = [
        {
            type: "Visa",
            lastDigits: "1234",
            expiry: "06/2024",
            logo: "https://upload.wikimedia.org/wikipedia/commons/0/04/Visa.svg",
        },
        {
            type: "Mastercard",
            lastDigits: "1234",
            expiry: "06/2024",
            logo: "https://upload.wikimedia.org/wikipedia/commons/2/2a/Mastercard-logo.svg",
        },
    ];

    const payments = [
        { title: "Professional – Dec 2022", amount: "USD $79", date: "Dec 1, 2022", status: "Paid" },
        { title: "Professional – Nov 2022", amount: "USD $79", date: "Nov 1, 2022", status: "Paid" },
        { title: "Professional – Oct 2022", amount: "USD $79", date: "Oct 1, 2022", status: "Paid" },
        { title: "Professional – Sep 2022", amount: "USD $79", date: "Sep 1, 2022", status: "Paid" },
        { title: "Professional – Aug 2022", amount: "USD $79", date: "Aug 1, 2022", status: "Paid" },
        { title: "Professional – Jul 2022", amount: "USD $79", date: "Jul 1, 2022", status: "Paid" },
        { title: "Professional – Jun 2022", amount: "USD $79", date: "Jun 1, 2022", status: "Paid" },
    ];

    const toggleInvoiceSelection = (title) => {
        setSelectedInvoices((prev) =>
            prev.includes(title) ? prev.filter((item) => item !== title) : [...prev, title]
        );
    };

    return (
        <div className="user-profile-payment-container">
            <h2 className="user-profile-payment-title">Payment Method</h2>
            <p className="user-profile-payment-subtitle">Update your payment details.</p>
            <hr />

            <div className="user-profile-payment-content">
                <div className="user-profile-payment-method">
                    <div>
                        <h3 className="user-profile-payment-section-title">Card details</h3>
                        <p className="user-profile-payment-section-subtitle">Select default payment method.</p>
                    </div>
                    <div className="user-profile-payment-cards">
                        {cards.map((card) => (
                            <div
                                key={card.type}
                                className={`user-profile-payment-card ${selectedCard === card.type ? "selected" : ""}`}
                                onClick={() => setSelectedCard(card.type)}
                            >
                                <div className="user-profile-payment-card-header">
                                    <div>
                                        <img src={card.logo} alt={`${card.type} logo`} className="user-profile-payment-card-logo" />
                                        <span>{card.type} ending in {card.lastDigits}</span>
                                    </div>
                                    <span className="user-profile-payment-card-radio">{selectedCard === card.type && "✔"}</span>
                                </div>
                                <p className="user-profile-payment-card-expiry">Expiry {card.expiry}</p>
                                <div className="user-profile-payment-card-actions">
                                    <span className="user-profile-payment-set-default">Set as default</span>
                                    <span className="user-profile-payment-edit">Edit</span>
                                </div>
                            </div>
                        ))}
                        <button className="user-profile-payment-add-card">+ Add new payment method</button>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', alignItems: 'center' }}>
                    <h3 className="user-profile-payment-section-title">Payment history</h3>
                    <button className="user-profile-payment-download-all">
                        <img style={{ cursor: 'pointer', height: '20px', width: '20px', marginRight: '10px' }} src={CloudIcon} alt="Icon" />
                        Download all
                    </button>
                </div>

                <div className="user-profile-payment-history">
                    <div className="user-profile-payment-table">
                        <div className="user-profile-payment-table-header">
                            <div>
                                <span>Invoice</span>
                            </div>
                            <div>
                                <span>Amount</span>
                                <span>Date</span>
                                <span>Status</span>
                                <span></span>
                            </div>
                        </div>
                        <div className="user-profile-payment-table-body">
                            {payments.map((payment) => (
                                <div key={payment.title} className="user-profile-payment-table-row">

                                    <div>
                                        <span>
                                            <span className="user-profile-payment-checkbox-container">
                                                <input
                                                    type="checkbox"
                                                    checked={selectedInvoices.includes(payment.title)}
                                                    onChange={() => toggleInvoiceSelection(payment.title)}
                                                    style={{cursor:'pointer'}}
                                                />
                                            </span>
                                            {payment.title}
                                        </span>
                                    </div>
                                    <div>
                                        <span>{payment.amount}</span>
                                        <span>{payment.date}</span>
                                        <span><span className="user-profile-payment-status-paid">{payment.status}</span></span>
                                        <span><img style={{ cursor: 'pointer', height: '20px', width: '20px', marginRight: '10px' }} src={CloudIcon} alt="Icon" /></span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserProfilePayment;
