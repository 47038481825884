import { useContext, useEffect, useState } from 'react';
import './UserProfileDashboard.css';
import UploadIcon from '../../assets/images/icons/Uploadicon.png';
import { AppContext } from '../../context/Context';
import { firebaseAuth } from '../../context/firebaseContext/firebase';
import { updateProfile } from 'firebase/auth';
import { useLoading } from '../../context/LoadingContext/LoadingContext';
import { updateUserProfileByUserId } from '../../api/subscription/subscription';
import showToast from '../../utils/showToast';

const UserProfileDashboard = () => {
  const { user } = useContext(AppContext);
  const { setIsLoading } = useLoading();

  const initialFormData = {
    firstName: user?.displayName?.split(' ')[0] || '',
    lastName: user?.displayName?.split(' ')[1] || '',
    email: user?.email || '',
    nationality: user?.FirebaseUser?.nationality || 'UK',
    address: user?.FirebaseUser?.address || '',
    photoURL: user?.photoURL,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [file, setFile] = useState(null);
  const [isFormDirty, setIsFormDirty] = useState(false);

  // Handle input field changes
  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevFormData => {
      const updatedFormData = {
        ...prevFormData,
        [name]: value,
      };
      setIsFormDirty(JSON.stringify(updatedFormData) !== JSON.stringify(initialFormData));
      return updatedFormData;
    });
  };

  // Handle file selection
  const handleFileChange = e => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const validFormats = ['image/jpeg', 'image/png'];
      if (!validFormats.includes(selectedFile.type)) {
        showToast('error', 'Please upload a file in JPG or PNG format.');
        return;
      }
      setFile(selectedFile);
    }
  };

  // Handle drag-and-drop file upload
  const handleDragOver = e => {
    e.preventDefault();
  };

  const handleDrop = e => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      setFile(droppedFile);
    }
  };

  const handleCancel = () => {
    setFormData(initialFormData);
    setIsFormDirty(false);
    setFile(null)
  };

  const validateForm = () => {
    const { firstName, lastName, email, nationality, address } = formData;
    if (!firstName || !lastName || !email || !nationality || !address) {
      return false;
    }
    return true;
  };

  const handleSaveChanges = async () => {
    if (!validateForm()) {
      alert('Please fill in all required fields.');
      return;
    }

    try {
      setIsLoading(true);
      const user = firebaseAuth.currentUser;

      const displayName = `${formData.firstName} ${formData.lastName}`;

      const updatedFields = {};
      updatedFields.displayName = displayName;

      const userId = user?.uid;
      const nationality = formData.nationality;
      const address = formData.address;

      let profileRes = await updateUserProfileByUserId(
        { userId, nationality, address },
        file
      );

      console.log('profileRes', profileRes);
      if (profileRes.success === true) {
        if (profileRes.data.fileUrl !== '') {
          updatedFields.photoURL = profileRes.data.fileUrl;
        }
      }
      await updateProfile(user, updatedFields);
      setIsLoading(false);
      window.location.reload();
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setFormData({
      firstName: user?.displayName?.split(' ')[0] || '',
      lastName: user?.displayName?.split(' ')[1] || '',
      email: user?.email || '',
      nationality: user?.FirebaseUser?.nationality || 'UK',
      address: user?.FirebaseUser?.address || '',
      photoURL: user?.photoURL,
    });
  }, [user]);

  return (
    <div className="user-profile-dashboard-container">
      <h2 className="user-profile-dashboard-title">Personal Info</h2>
      <p style={{ fontWeight: 'normal', fontSize: '13px' }}>
        Update your photo and personal details here.
      </p>
      <hr />

      <div className="user-profile-input-group">
        <label className="user-profile-label">Name</label>
        <div className="user-profile-name-fields">
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            className="user-profile-input-field"
          />
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            className="user-profile-input-field"
          />
        </div>
      </div>

      <div className="user-profile-input-group">
        <label className="user-profile-label">Email Address</label>
        <input
          disabled
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          className="user-profile-input-field"
        />
      </div>

      <div className="user-profile-input-group">
        <label className="user-profile-label">
          Your Photo
          <p style={{ fontWeight: 'normal', fontSize: '13px' }}>
            This will be displayed on your profile.
          </p>
        </label>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '55%',
          }}
        >
          {formData.photoURL && (
            <img
              src={formData.photoURL}
              alt="Profile"
              className="user-profile-pic"
            />
          )}
          <div
            className="upload-container"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <input
              type="file"
              accept="image/*"
              className="hidden-input"
              onChange={handleFileChange}
              id="fileInput"
            />
            {!file && <>
              <label htmlFor="fileInput" className="label">
                <div className="upload-icon">
                  <img src={UploadIcon} alt="" />
                </div>
                <p>
                  <span className="upload-text">Click to upload</span> or drag and
                  drop
                </p>
                <p className="supported-formats">
                  SVG, PNG, JPG or GIF (max. 800×400px)
                </p>
              </label>
            </>}

            {file && (
              <div className="file-preview" onClick={() => document.getElementById('fileInput').click()}>
                <img
                  src={file.url}
                  alt="Uploaded file"
                  className="preview-image"
                />
                <span className="file-tag">
                  {file.name.split('.').pop().toUpperCase()}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="user-profile-input-group">
        <label className="user-profile-label">Nationality</label>
        <select
          name="nationality"
          value={formData.nationality}
          onChange={handleChange}
          className="user-profile-input-field"
        >
          <option value="Australia">Australia</option>
          <option value="USA">USA</option>
          <option value="UK">UK</option>
        </select>
      </div>

      <div className="user-profile-input-group">
        <label className="user-profile-label">Address</label>
        <input
          type="text"
          name="address"
          value={formData.address}
          onChange={handleChange}
          className="user-profile-input-field"
        />
      </div>

      <div className="user-profile-button-group">
        <button
          className="user-profile-cancel-button"
          onClick={handleCancel}
          disabled={!isFormDirty}
        >
          Cancel
        </button>
        <button
          className={`user-profile-save-button ${!isFormDirty ? 'disabled' : ''}`}
          onClick={handleSaveChanges}
          disabled={!isFormDirty}
        >
          Save changes
        </button>
      </div>
    </div>
  );
};

export default UserProfileDashboard;
