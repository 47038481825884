import React, { useContext, useEffect, useState } from 'react';
import TableLoadingScreen from '../../../LoadingScreen/TableLoadingScreen/TableLoadingScreen';
import NumberDisplay from '../../../NumberDisplay/NumberDisplay';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import LanguageIcon from '@material-ui/icons/Language';
import '../CompanyInfoModal.css';
import { AppContext } from '../../../../context/Context';
import { getNodeNameById } from '../../../../utils/helper';

function Shareholders({
  debouncedSearch,
  isLoading,
  shareholders,
  smallIcons = false,
  selectedCompany,
  forceMobileView = false,
}) {
  const { trackShareholder, setTrackShareholder, clGraph } =
    useContext(AppContext);

  const [isTrackingOn, setIsTrackingOn] = useState(false);

  const trackShareholdersInGraph = () => {
    const nameKey = getNodeNameById(
      clGraph?.selectedGraph?.nodes,
      clGraph?.selectedGraph?.meta?.selectedEntity?.ids
    )?.replaceAll(' ', '_');

    const newItem = {
      companyNumber: selectedCompany.company_number,
      shareholders: shareholders,
    };

    setTrackShareholder(prev => {
      const existingArray = prev[nameKey] || [];
      const updatedArray = [...existingArray, newItem];
      return {
        ...prev,
        [nameKey]: updatedArray,
      };
    });
  };

  const removeTrackShareholdersInGraph = () => {
    let companyNumber = selectedCompany?.company_number;
    setTrackShareholder(prev => {
      // Make a shallow copy of the entire object
      const newState = { ...prev };

      // For each nameKey in trackShareholder,
      // filter out the item(s) whose companyNumber matches
      for (const nameKey in newState) {
        newState[nameKey] = newState[nameKey].filter(
          item => item.companyNumber !== companyNumber
        );
      }

      return newState;
    });
  };

  const isCompanyPartOfTrackingShareholders = (records, companyNumber) => {
    // .some() returns true if at least one item satisfies the condition
    return records?.some(record => record?.companyNumber === companyNumber);
  };

  useEffect(() => {
    const nameKey = getNodeNameById(
      clGraph?.selectedGraph?.nodes,
      clGraph?.selectedGraph?.meta?.selectedEntity?.ids
    )?.replaceAll(' ', '_');

    setIsTrackingOn(
      isCompanyPartOfTrackingShareholders(
        trackShareholder[nameKey],
        selectedCompany.company_number
      )
    );
  }, [trackShareholder]);

  return (
    <div>
      <div
        className={`company-info-body-scroller ${
          smallIcons ? 'company-info-body-scroller-insight' : ''
        }`}
      >
        {forceMobileView === true ? (
          <div>
            {isTrackingOn === false || isTrackingOn === undefined ? (
              <div>
                <button
                  style={{
                    backgroundColor: '#0380C7', // Green background
                    border: 'none', // No border
                    color: 'white', // White text
                    padding: '5px 5px', // Padding
                    textAlign: 'center', // Centered text
                    textDecoration: 'none', // No underline
                    display: 'inline-block', // Inline block
                    borderRadius: '5px', // Rounded corners
                    cursor: 'pointer', // Pointer cursor on hover
                    transition: 'background-color 0.3s ease', // Smooth transition for background color
                    marginBottom: '5px',
                  }}
                  onClick={() => trackShareholdersInGraph()}
                >
                  Track in Graph
                </button>
              </div>
            ) : (
              <div>
                <button
                  style={{
                    backgroundColor: '#0380C7', // Green background
                    border: 'none', // No border
                    color: 'white', // White text
                    padding: '5px 5px', // Padding
                    textAlign: 'center', // Centered text
                    textDecoration: 'none', // No underline
                    display: 'inline-block', // Inline block
                    borderRadius: '5px', // Rounded corners
                    cursor: 'pointer', // Pointer cursor on hover
                    transition: 'background-color 0.3s ease', // Smooth transition for background color
                    marginBottom: '5px',
                  }}
                  onClick={() => removeTrackShareholdersInGraph()}
                >
                  Remove Tracking in Graph
                </button>
              </div>
            )}
          </div>
        ) : (
          ''
        )}
        <div className="company-info-modal-people-contant-main">
          <div className="company-info-modal-overview-main">
            <div
              className="company-info-modal-overview-title"
              style={{
                display: 'flex',
                gap: '5px',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              Shareholders
              <div>
                <input
                  className="form-control"
                  type="search"
                  placeholder="Search Shareholder ..."
                  onChange={e => {
                    const value = e.target.value;
                    debouncedSearch(value);
                  }}
                />
              </div>
            </div>
            {isLoading === false ? (
              <div className="company-info-modal-Table-container">
                {shareholders && Array.isArray(shareholders) && (
                  <>
                    {shareholders
                      ?.filter(shareType => shareType.type === 'Preferred')
                      .map((shareType, index) => (
                        <div
                          key={index}
                          className="company-info-modal-share-type"
                        >
                          <div className="company-info-modal-header">
                            <div className="company-info-modal-shares-type">
                              {shareType?.type}{' '}
                              <span style={{ marginLeft: '5px' }}>Shares</span>
                            </div>
                            <div className="company-info-modal-total-shares">
                              <span
                                style={{
                                  marginRight: '5px',
                                  fontWeight: '600px',
                                }}
                              >
                                Total Shares:
                              </span>
                              <NumberDisplay
                                animatedValue={shareType?.total_shares}
                              />
                            </div>
                          </div>
                          <table className="company-info-modal-shareholders-table">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th className="preferred-column">Shares</th>
                                <th className="percentage-column">
                                  Percentage
                                </th>
                                <th className="company-info-modal-shareholder-socialIcon-main"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {shareType.people.map((person, idx) => (
                                <tr
                                  key={idx}
                                  className="company-info-modal-shareholder"
                                >
                                  <td className="company-info-modal-shareholders-name">
                                    {person.name}
                                    <div className="sub-text">
                                      {person.type.toUpperCase()}
                                    </div>
                                  </td>
                                  <td className="preferred-column">
                                    <NumberDisplay
                                      animatedValue={person.number_of_shares}
                                    />
                                  </td>
                                  <td className="percentage-column">
                                    {person.shares_percentage}
                                  </td>
                                  <td className="company-info-modal-shareholder-socialIcon-main">
                                    <div
                                      className={`company-info-modal-shareholder-socialIcons ${
                                        smallIcons
                                          ? 'company-info-modal-shareholder-socialIcons-insights'
                                          : ''
                                      }`}
                                    >
                                      <a
                                        href={`https://www.linkedin.com/search/results/all/?keywords=${person?.name}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <LinkedInIcon
                                          className={
                                            smallIcons &&
                                            'company-info-modal-shareholder-socialIcons-size'
                                          }
                                        />
                                      </a>
                                      <a
                                        href={`https://www.google.com/search?q=${person?.name}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <LanguageIcon
                                          className={
                                            smallIcons &&
                                            'company-info-modal-shareholder-socialIcons-size'
                                          }
                                        />
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ))}

                    {shareholders
                      .filter(shareType => shareType.type !== 'Preferred')
                      .map((shareType, index) => (
                        <div
                          key={index}
                          className="company-info-modal-share-type"
                        >
                          <div className="company-info-modal-header">
                            <div className="company-info-modal-shares-type">
                              {shareType?.type}{' '}
                              <span style={{ marginLeft: '5px' }}>Shares</span>
                            </div>
                            <div className="company-info-modal-total-shares">
                              <span
                                style={{
                                  marginRight: '5px',
                                  fontWeight: '600px',
                                }}
                              >
                                Total Shares:
                              </span>
                              <NumberDisplay
                                animatedValue={shareType?.total_shares}
                              />
                            </div>
                          </div>
                          <table className="company-info-modal-shareholders-table">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th className="preferred-column">Shares</th>
                                <th className="percentage-column">
                                  Percentage
                                </th>
                                <th className="company-info-modal-shareholder-socialIcon-main"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {shareType.people.map((person, idx) => (
                                <tr
                                  key={idx}
                                  className="company-info-modal-shareholder"
                                >
                                  <td className="company-info-modal-shareholders-name">
                                    {person.name}
                                    <div className="sub-text">
                                      {person.type.toUpperCase()}
                                    </div>
                                  </td>
                                  <td className="preferred-column">
                                    <NumberDisplay
                                      animatedValue={person.number_of_shares}
                                    />
                                  </td>
                                  <td className="percentage-column">
                                    {person.shares_percentage}
                                  </td>
                                  <td className="company-info-modal-shareholder-socialIcon-main">
                                    <div
                                      className={`company-info-modal-shareholder-socialIcons ${
                                        smallIcons
                                          ? 'company-info-modal-shareholder-socialIcons-insights'
                                          : ''
                                      }`}
                                    >
                                      <a
                                        href={`https://www.linkedin.com/search/results/all/?keywords=${person?.name}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <LinkedInIcon
                                          className={
                                            smallIcons &&
                                            'company-info-modal-shareholder-socialIcons-size'
                                          }
                                        />
                                      </a>
                                      <a
                                        href={`https://www.google.com/search?q=${person?.name}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <LanguageIcon
                                          className={
                                            smallIcons &&
                                            'company-info-modal-shareholder-socialIcons-size'
                                          }
                                        />
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ))}
                  </>
                )}
              </div>
            ) : (
              <TableLoadingScreen />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Shareholders;
