import axios from 'axios';
import { apiConfig, buildUrl } from '../apiConfig';

export const getCompaniesAndOfficerHistoryForWatchlist =
  async company_number => {
    try {
      const response = await axios.get(
        buildUrl(
          apiConfig.base,
          apiConfig.watchList.endpoints
            .getCompaniesAndOfficerHistoryForWatchlist
        ),
        { params: { company_number: company_number } }
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching users:', error);
      return {
        success: false,
        response: error?.response,
        status: error?.response?.status,
      };
    }
  };

export const getTrackingWatchListByUserId = async user_id => {
  try {
    const response = await axios.get(
      buildUrl(
        apiConfig.base,
        apiConfig.watchList.endpoints.getTrackingWatchListByUserId
      ),
      { params: { user_id: user_id } }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    return {
      success: false,
      response: error?.response,
      status: error?.response?.status,
    };
  }
};

export const getWatchListByCompanyNumberAndUserId = async (
  user_id,
  company_number
) => {
  try {
    const response = await axios.post(
      buildUrl(
        apiConfig.base,
        apiConfig.watchList.endpoints.getWatchListByCompanyNumberAndUserId
      ),
      { user_id, company_number }
    );
    return response.data;
  } catch (error) {
    console.error(
      'Error fetching watchlist by company number and user ID:',
      error
    );
    return {
      success: false,
      response: error?.response,
      status: error?.response?.status,
    };
  }
};

export const deleteWatchListForCompanyByUserId = async (
  user_id,
  company_number,
  relationship
) => {
  try {
    const response = await axios.post(
      buildUrl(
        apiConfig.base,
        apiConfig.watchList.endpoints.deleteWatchListForCompanyByUserId
      ),
      { user_id, company_number, relationship }
    );
    return response.data;
  } catch (error) {
    console.error('Error deleting company from watchlist:', error);
    return {
      success: false,
      response: error?.response,
      status: error?.response?.status,
    };
  }
};

export const setWatchListForCompanyByUserId = async (
  user_id,
  company_number,
  relationship
) => {
  try {
    const response = await axios.post(
      buildUrl(
        apiConfig.base,
        apiConfig.watchList.endpoints.setWatchListForCompanyByUserId
      ),
      { user_id, company_number, relationship }
    );
    return response.data;
  } catch (error) {
    console.error('Error setting company in watchlist:', error);
    return {
      success: false,
      response: error?.response,
      status: error?.response?.status,
    };
  }
};
