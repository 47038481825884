import {
  setPersistence,
  browserLocalPersistence,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { firebaseAuth } from '../../context/firebaseContext/firebase';
import { getUserById } from '../outseta/outseta';
import { mapUserData } from '../../utils/helper';

export const loginWithEmail = async (email, password) => {
  try {
    await setPersistence(firebaseAuth, browserLocalPersistence); // Persist session
    const userCredential = await signInWithEmailAndPassword(
      firebaseAuth,
      email,
      password
    );
    const user = userCredential.user;
    // Check if the user exists in your database
    let firebaseUser = await getUserById(user.uid);
    return {
      success: true,
      user: mapUserData(user),
      firebaseUser: firebaseUser?.data,
    };
  } catch (error) {
    let errorMessage = 'An error occurred during login';
    switch (error.code) {
      case 'auth/user-not-found':
        errorMessage = 'No user found with this email';
        break;
      case 'auth/wrong-password':
        errorMessage = 'Invalid password. Please try again';
        break;
      case 'auth/too-many-requests':
        errorMessage = 'Too many login attempts. Try again later';
        break;
      default:
        errorMessage = error.message;
    }
    console.error('Login error:', error);
    return {
      success: false,
      error: errorMessage,
      code: error.code,
    };
  }
};
