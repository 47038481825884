import React, { useContext, useEffect, useState } from "react";
import "./SIMyPurchases.css";
import { downloadReport, getJudgementCheckerReports } from "../../api/judgementChecker/judgementChecker";
import { useLoading } from "../../context/LoadingContext/LoadingContext";
import { AppContext } from "../../context/Context";
import Pagination from '../../components/Pagination/Pagination';
import { determineCaseColor, determineCaseOutcome, formatDateFromString } from "../../utils/helper";
import JudgementCheckerSquare from '../../pages/JudgementChecker/JudgmentCheckerReport/Report/JudgementCheckerSquare';
import ReportModal from '../../components/ReportModal/ReportModal';
import MenuIcon from '../../assets/images/icons/MenuIcon.png';
import SearchIconColor from '../../assets/images/icons/SearchIconColor.png';
import combinationIcon from '../../assets/images/icons/combinationIcon.png';
import referenceIcon from '../../assets/images/icons/referenceIcon.png';
import overallResult from '../../assets/images/icons/overallResult.png';
import ViewIcon from '../../assets/images/icons/ViewIcon.png';
import redirectIcon from '../../assets/images/icons/redirectIcon.png';

const SIMyPurchases = ({
    userId = null,
}) => {
    const [reports, setReports] = useState([]);
    const { setIsLoading } = useLoading();
    const { user } = useContext(AppContext);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');

    const fetchReports = async currentPage => {
        try {
            setIsLoading(true);
            let response = await getJudgementCheckerReports(
                userId !== null ? userId : user?.FirebaseUser?.user_id,
                currentPage + 1,
                5
            );

            if (!response.success) {
                setIsLoading(false);
                return;
            }

            setReports(response.data);
            setTotalPages(response.totalPages);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (user) {
            const apiCall = async () => {
                await fetchReports(currentPage);
            };

            apiCall();
        }
    }, [currentPage, userId]);

    const handlePageChange = newPage => {
        setCurrentPage(newPage);
    };

    return (
        <div className="up-record-list-container">
            {/* Table Header */}
            <div className="up-mp-record-header">
                <span className="up-mp-column-title">
                    <img style={{ height: '26px', width: '26px', marginRight: '5px' }} src={MenuIcon} alt="Icon" />
                    Generated On</span>
                <span className="up-mp-column-title">
                    <img style={{ height: '26px', width: '26px', marginRight: '5px' }} src={SearchIconColor} alt="Icon" />
                    Entity Searched</span>
                <span className="up-mp-column-title">
                    <img style={{ height: '26px', width: '26px', marginRight: '5px' }} src={combinationIcon} alt="Icon" />
                    Type</span>
                <span className="up-mp-column-title">
                    <img style={{ height: '26px', width: '26px', marginRight: '5px' }} src={referenceIcon} alt="Icon" />
                    Reference</span>
                <span className="up-mp-column-title">
                    <img style={{ height: '26px', width: '26px', marginRight: '5px' }} src={overallResult} alt="Icon" />
                    Overall Result</span>
                <span className="up-mp-column-title">
                    <img style={{ height: '26px', width: '26px', marginRight: '5px' }} src={ViewIcon} alt="Icon" />
                    View Report</span>
            </div>

            {/* Records */}
            {reports.map((report, index) => (
                <div key={index} className="up-mp-record-row">
                    <span className="up-mp-record-item">{formatDateFromString(
                        report?.judgementDetail?.uniqueReferenceNumber
                    )}</span>
                    <span className="up-mp-record-item">{
                        report?.judgementDetail?.judgmentCheckerType
                            ?.type === 'BusinessSearch'
                            ? report?.judgementDetail?.judgmentCheckerType
                                .companyName
                            : report?.judgementDetail?.judgmentCheckerType
                                ?.personInfo?.firstName +
                            ' ' +
                            report?.judgementDetail?.judgmentCheckerType
                                ?.personInfo?.lastName
                    }
                    </span>
                    <span className="up-mp-record-item">{report?.judgementDetail?.judgmentCheckerType?.type}</span>
                    <span className="up-mp-record-item">{report?.judgementDetail?.uniqueReferenceNumber}</span>
                    <span className="up-mp-record-item">
                        {determineCaseOutcome(report.judgementDetail?.judgmentCheckerType
                            ?.JudgementChecker)}
                    </span>

                    <span className="up-mp-record-item">
                        <img style={{ height: '17px', width: '17px', marginRight: '7px' }} src={redirectIcon} alt="Icon" />
                        <span
                            className="body-text-bold"
                            style={{
                                color: '#475467',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                                fontSize: '14px'
                            }}
                            onClick={() => {
                                /* function to handle view */
                                setShowModal(!showModal);
                                setPdfUrl(report?.link);
                            }}
                        >
                            Click Here
                        </span>
                    </span>
                </div>
            ))}

            <div>
                <ReportModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    pdfUrl={pdfUrl}
                />
            </div>

            <div style={{ marginTop: '25px' }}></div>
            <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
            />
        </div>
    );
};

export default SIMyPurchases;
