import React from 'react'
import fingerPrintIcon from '../../assets/images/icons/fingerPrintLogo.png';
import facebookIcon from '../../assets/images/icons/facebook-Vector.png';
import twitterIcon from '../../assets/images/icons/X-Vector.png';
import linkedinIcon from '../../assets/images/icons/LinkedIn-Vector.png';
import phoneVector from '../../assets/images/icons/callVector.png';
import messageVector from '../../assets/images/icons/messageVector.png';
import contactVector from '../../assets/images/icons/contactVector.png';
import headsetVector from '../../assets/images/icons/HeadsetVector.png';
import { useNavigate } from 'react-router';

const MainFooter = () => {
    const navigate = useNavigate();

    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-left">
                    <h3>
                        <img
                            width={25}
                            height={35}
                            src={fingerPrintIcon}
                            alt=""
                            style={{ marginRight: '10px' }}
                        />
                        ProbeDigital
                    </h3>
                    <p>Welcome to ProbeDigital, the pioneering</p>
                    <p>force in Regulatory Tech (RegTech).</p>
                    <div className="footer-social-icons">
                        <img src={twitterIcon} alt="Twitter" />
                        <img src={linkedinIcon} alt="LinkedIn" />
                        <img src={facebookIcon} alt="Facebook" />
                    </div>
                </div>
                <div className="footer-right">
                    <h3 style={{ cursor: 'pointer' }}>Get In Touch</h3>
                    <p>
                        <img
                            src={headsetVector}
                            alt="headsetVector"
                            width={15}
                            height={15}
                            style={{ marginRight: '10px' }}
                        />{' '}
                        +44 207 046 0564
                    </p>
                    <p>
                        <img
                            src={phoneVector}
                            alt="phoneVector"
                            width={15}
                            height={15}
                            style={{ marginRight: '10px' }}
                        />{' '}
                        +44 020 8144 2812
                    </p>
                    <p>
                        <img
                            src={messageVector}
                            alt="messageVector"
                            width={15}
                            height={15}
                            style={{ marginRight: '10px' }}
                        />{' '}
                        contact@probedigital.co.uk
                    </p>
                    <p>
                        <img
                            src={contactVector}
                            alt="contactVector"
                            width={15}
                            height={15}
                            style={{ marginRight: '10px' }}
                        />{' '}
                        contact@probedigital.co.uk
                    </p>
                </div>
            </div>
            <div className="footer-bottom">
                <p>Copyright © 2025 All Rights Reserved.</p>
                <p>
                    <a href="#">Privacy Policy</a> | <a href="#">Terms & Conditions</a>
                </p>
            </div>
        </footer>
    )
}

export default MainFooter
