import './Home.css';
import React, { useEffect, useState, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import Header from '../../components/Header';
import Banner from '../../components/Banner';
// import Footer from '../../components/Footer';
// import CopywriteLiine from '../../components/CopywriteLiine';
import Individuals from '../../assets/images/pages/Home/individuals.png';
import Investors from '../../assets/images/pages/Home/investors.png';
import LegalFirms from '../../assets/images/pages/Home/legal-firms.png';
import Businesses from '../../assets/images/pages/Home/businesses.png';
import WhoAreWe from '../../assets/images/pages/Home/who-are-we.png';
import OurServices from '../../assets/images/pages/Home/our-services.png';
import OurApproach from '../../assets/images/pages/Home/our-approach.png';
import OurGoal from '../../assets/images/pages/Home/our-goal.png';
import GlobeProperty from '../../assets/images/icons/Globe_Property_Icon.png';
import SearchIcon from '../../assets/images/what/company.png';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppContext } from '../../context/Context';
import CONSTANTS from '../../utils/constants';
import { useAuth } from '../../context/AuthProvider';
import PostAuthFlow from './PostAuthFlow/PostAuthFlow';
import Footer from './../../components/Footer';
import CopywriteLiine from './../../components/CopywriteLiine';
import StartJourney from './StartJourney/StartJourney';

function Home() {
  const navigate = useNavigate();
  const { openSignup } = useAuth();
  const { user, setUser } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [testHomeComponent, setTestHomeComponent] = useState(null);

  const handleClose = () => {
    setShowModal(false);
    setSearchParams({});
  };

  useEffect(() => {
    const probeToken = searchParams.get('probe_token');
    if (probeToken === '01eb26ec-9fbb-11ee-8c90-0242ac120002') {
      setShowModal(true);
    }
  }, [searchParams]);

  useEffect(() => {
    if (process.env.REACT_APP_ENV_NAME === 'prod') {
      setTestHomeComponent(false);
      //window.location.href = CONSTANTS.HUB_SPOT_URL;
      window.location.href = CONSTANTS.PRODUCTION_SITE_CL;
    } else {
      navigate('/services');
    }
  }, []);

  return <></>;
}

export default Home;
