import React, { useState, useEffect, useContext } from 'react';
import './CESAdvancedFilter.css';
import DateRangeSlider from './CESFilterSlider/CESFilterSlider';
import { AppContext } from '../../../../context/Context';
import ProbeButton from '../../../../components/ProbeButton/ProbeButton';

const CESAdvancedFilter = ({ inputValue, setInputValue }) => {
  const minCessationDate = 1800;
  const maxCessationDate = 2024;
  const minCreationDate = 1800;
  const maxCreationDate = 2024;
  const { corporateLinksAdvanceFilters, setCorporateLinksAdvanceFilters } =
    useContext(AppContext);

  // Temporary state for filters
  const [tempFilterCompany, setTempFilterCompany] = useState({
    title: '',
    company_status: '',
    company_number: '',
    company_type: '',
    nationality: '',
    postal_code: '',
    creationDateRange: [minCreationDate, maxCreationDate],
    cessationDateRange: [minCessationDate, maxCessationDate],
  });

  const [isSearchEnabled, setIsSearchEnabled] = useState(false);

  const handleSelectChange = e => {
    const { name, value } = e.target;
    if (name === 'title') {
      setInputValue(value);
    }

    // Convert value to lowercase if the field is 'company_status'
    const updatedValue =
      name === 'company_status' ? value.toLowerCase() : value;

    setTempFilterCompany(prevFilters => ({
      ...prevFilters,
      [name]: updatedValue,
    }));
  };

  const handleCreationDateRangeChange = newRange => {
    setTempFilterCompany(prevFilters => ({
      ...prevFilters,
      creationDateRange: newRange,
    }));
  };

  const handleCessationDateRangeChange = newRange => {
    setTempFilterCompany(prevFilters => ({
      ...prevFilters,
      cessationDateRange: newRange,
    }));
  };

  useEffect(() => {
    // Check if any field in the filters object has a value (excluding default values)
    const isAnyFieldSelected =
      tempFilterCompany?.company_status !== '' ||
      tempFilterCompany?.company_number !== '' ||
      tempFilterCompany?.title !== '' ||
      tempFilterCompany?.nationality !== '' ||
      tempFilterCompany?.postal_code !== '' ||
      tempFilterCompany?.creationDateRange[0] !== minCreationDate ||
      tempFilterCompany?.creationDateRange[1] !== maxCreationDate ||
      tempFilterCompany?.cessationDateRange[0] !== minCessationDate ||
      tempFilterCompany?.cessationDateRange[1] !== maxCessationDate ||
      tempFilterCompany?.company_type !== '';

    // Set the search enabled state
    setIsSearchEnabled(isAnyFieldSelected);
  }, [tempFilterCompany]);

  const applyFilters = () => {
    // Create a new filters object excluding default values
    const filtersToApply = {
      ...tempFilterCompany,
      company_status:
        tempFilterCompany.company_status === ''
          ? undefined
          : tempFilterCompany.company_status,
      company_type:
        tempFilterCompany.company_type === ''
          ? undefined
          : tempFilterCompany.company_type,
      nationality:
        tempFilterCompany.nationality === ''
          ? undefined
          : tempFilterCompany.nationality,
      company_number: tempFilterCompany.company_number || '',
      postal_code: tempFilterCompany.postal_code || '',
    };

    setCorporateLinksAdvanceFilters({
      ...corporateLinksAdvanceFilters,
      companyFilters: filtersToApply,
    });
  };

  return (
    <div className="company-advance-filter-container">
      <div className="company-advance-filter-row">
        <div className="company-advance-filter-col">
          <label htmlFor="title" className="form-label filter-label">
            Company Name
          </label>
          <input
            className="form-control company-person-advance-filter-form-control"
            id="title"
            placeholder="Company Name"
            name="title"
            value={inputValue}
            onChange={handleSelectChange}
            disabled
          />
        </div>
        <div className="company-advance-filter-col">
          <label htmlFor="company_number" className="form-label filter-label">
            Company Number
          </label>
          <input
            className="form-control company-person-advance-filter-form-control"
            id="company_number"
            placeholder="Company Number"
            name="company_number"
            value={tempFilterCompany?.company_number}
            onChange={handleSelectChange}
          />
        </div>
      </div>
      <div className="company-advance-filter-row">
        <div className="company-advance-filter-col">
          <label htmlFor="nationality" className="form-label filter-label">Location :</label>
          <select
            className="form-control company-person-advance-filter-form-control"
            id="nationality"
            name="nationality"
            value={tempFilterCompany?.nationality}
            onChange={handleSelectChange}
          >
            <option value="">Location </option>
            <option>Chesterfield</option>
            <option>Grimsby</option>
            <option>Milton Keynes</option>
            <option>London</option>
            <option>Leamington SPA</option>
            <option>Manchester</option>
            <option>Reading</option>
          </select>
        </div>
        <div className="company-advance-filter-col">
          <label htmlFor="postal_code" className="form-label filter-label">
            Postal Code
          </label>
          <input
            className="form-control company-person-advance-filter-form-control"
            id="postal_code"
            placeholder="Post Code"
            name="postal_code"
            value={tempFilterCompany?.postal_code}
            onChange={handleSelectChange}
          />
        </div>
      </div>
      <div className="company-advance-filter-row">
        <div className="company-advance-filter-col">
          <label htmlFor="company_status" className="form-label filter-label">
            Company Status:
          </label>
          <select
            className="form-control company-person-advance-filter-form-control"
            id="company_status"
            name="company_status"
            value={tempFilterCompany?.company_status}
            onChange={handleSelectChange}
          >
            <option value="">company_status</option>
            <option>active</option>
            <option>dissolved</option>
            <option>dormant</option>
            <option>in Liquidation</option>
          </select>
        </div>
        <div className="company-advance-filter-col">
          <label htmlFor="company_type" className="form-label filter-label">
            Company Type:
          </label>
          <select
            className="form-control company-person-advance-filter-form-control"
            id="company_type"
            name="company_type"
            value={tempFilterCompany?.company_type}
            onChange={handleSelectChange}
          >
            <option value="">Type</option>
            <option>All Private Companies</option>
            <option>PUL with Share Capitals</option>
            <option>PUL without Share Capitals</option>
          </select>
        </div>
      </div>
      <div className="company-advance-filter-row">
        <div className="company-advance-filter-col">
          <DateRangeSlider
            labelName={'Creation Period'}
            values={tempFilterCompany.creationDateRange}
            setValues={handleCreationDateRangeChange}
            minDate={minCreationDate}
            maxDate={maxCreationDate}
          />
        </div>
        <div className="company-advance-filter-col">
          <DateRangeSlider
            labelName={'Cessation Period'}
            values={tempFilterCompany.cessationDateRange}
            setValues={handleCessationDateRangeChange}
            minDate={minCessationDate}
            maxDate={maxCessationDate}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 d-flex justify-content-center">
          <ProbeButton
            width="343px"
            height="40px"
            name="Search"
            borderRadius="24px"
            backgroundColor={
              isSearchEnabled
                ? 'linear-gradient(to right, #0466D1, #00D1A9)'
                : '#8A8A8A'
            }
            fontColor="white"
            borderColor={isSearchEnabled ? '' : '#8A8A8A'}
            disabled={!isSearchEnabled}
            onClick={applyFilters}
          />
        </div>
      </div>
    </div>
  );
};

export default CESAdvancedFilter;
