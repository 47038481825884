import React, { useState } from 'react';
import './ForgetScreen.css';
import fingerPrintLogo from '../../assets/images/icons/fingerPrintLogo.png';
import { sendPasswordResetEmail } from 'firebase/auth';
import { firebaseAuth } from '../../context/firebaseContext/firebase'; // Adjust path based on your project structure
import showToast from '../../utils/showToast';
import { useNavigate } from 'react-router';

const ForgetScreen = ({ setStep }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [validationError, setValidationError] = useState(''); // State for validation errors

  // Validation function
  const validateEmail = () => {
    if (!email) {
      setValidationError('Email is required');
      return false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setValidationError('Email address is invalid');
      return false;
    }
    setValidationError('');
    return true;
  };

  const handleForgetConfirm = async () => {
    setMessage('');
    setError('');
    setValidationError('');

    // Validate email before proceeding
    if (!validateEmail()) {
      return;
    }

    try {
      await sendPasswordResetEmail(firebaseAuth, email);
      setMessage(
        'Password reset email sent successfully! Please check your email.'
      );
      showToast('success', 'Password reset email sent successfully!');
      setTimeout(() => {
        window.location.reload(); // Refresh the app
      }, 1000);
    } catch (err) {
      if (err.code === 'auth/user-not-found') {
        showToast('error', 'Email does not exist in our system');
        setError('Email does not exist in our system');
      } else {
        showToast(
          'error',
          err.message || 'Something went wrong. Please try again.'
        );
        setError(err.message || 'Something went wrong. Please try again.');
      }
    }
  };

  return (
    <div className="probe-login-container">
      {/* Login Section */}
      <div className="probe-login-container-inner-div">
        <div className="probe-login-container-logo-div">
          <img
            className="probe-login-container-logo"
            src={fingerPrintLogo}
            alt="Logo"
          />
        </div>
        <h2 className="probe-login-title">Forget Password</h2>
        <p
          style={{
            textAlign: 'center',
            fontSize: '12px',
            lineHeight: '18px',
            marginBottom: '35px',
          }}
        >
          Please enter the email address you’d like your password reset
          information sent to
        </p>

        {/* Email Input */}
        <label
          style={{ fontWeight: '500', fontSize: '12px', marginTop: '8px' }}
          htmlFor="email"
        >
          Enter email address
        </label>
        <input
          type="email"
          className="probe-login-input"
          placeholder="Enter your email here"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        {validationError && (
          <p className="probe-login-error">{validationError}</p>
        )}

        {/* Recover Password Button */}
        <button onClick={handleForgetConfirm}>Recover Password</button>

        {/* Display success or error messages */}
        {message && <p className="probe-login-success">{message}</p>}
        {error && <p className="probe-login-error">{error}</p>}
      </div>
    </div>
  );
};

export default ForgetScreen;