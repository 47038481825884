import React, { useState } from 'react';
import { getAuth, confirmPasswordReset } from 'firebase/auth';
import fingerPrintLogo from '../../assets/images/icons/fingerPrintLogo.png';
import { useNavigate } from 'react-router';

const ResetPasswordScreen = () => {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState(
    'Your new password must be different from previously used password'
  );
  const [oobCode, setOobCode] = useState('');

  // Parse the URL to extract the `oobCode`
  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('oobCode');
    if (code) {
      setOobCode(code);
    } else {
      setMessage('Invalid or missing reset code.');
    }
  }, []);

  const handleResetPassword = async () => {
    if (!newPassword || !confirmPassword || !oobCode) {
      setMessage('Please fill in all fields.');
      return;
    }
    if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }

    const auth = getAuth();
    try {
      await confirmPasswordReset(auth, oobCode, newPassword);
      setMessage('Password has been reset successfully!');
      window.location.reload();
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div className="probe-login-container">
      <div className="probe-login-container-inner-div">
        <div className="probe-login-container-logo-div">
          <img
            className="probe-login-container-logo"
            src={fingerPrintLogo}
            alt="Logo"
          />
        </div>
        <h2 className="probe-login-title">Enter New Password</h2>

        <p className="probe-reset-title">{message}</p>
        <label
          style={{ fontWeight: '500', fontSize: '12px', marginTop: '8px' }}
          htmlFor="newPassword"
        >
          Create New Password
        </label>
        <input
          type="password"
          className="probe-login-input"
          placeholder="Enter new password"
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
        />
        <label
          style={{ fontWeight: '500', fontSize: '12px', marginTop: '8px' }}
          htmlFor="confirmPassword"
        >
          Confirm Password
        </label>
        <input
          type="password"
          className="probe-login-input"
          placeholder="Confirm your password"
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}
        />

        <button onClick={handleResetPassword}>Reset Password</button>
      </div>
    </div>
  );
};

export default ResetPasswordScreen;
