import React, { useContext, useEffect } from 'react';
import { Outlet } from 'react-router';
import UserProfileSideMenu from '../../components/UserProfile/SideMenu/UserProfileSideMenu';
import { AppContext } from '../../context/Context';
import { firebaseAuth } from '../../context/firebaseContext/firebase';

const UserProfileLayout = () => {
  const layoutStyles = {
    display: 'flex',
    height: '87vh',
    flexDirection: 'row',
  };

  const contentStyles = {
    flex: 1,
    overflowY: 'auto',
  };

  return (
    <div style={layoutStyles}>
      <UserProfileSideMenu />
      <div style={contentStyles}>
        <Outlet />
      </div>
    </div>
  );
};

export default UserProfileLayout;
